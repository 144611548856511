import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress, Menu, MenuItem } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { ShimmerTable } from "react-shimmer-effects";
import { FaCaretDown } from "react-icons/fa";
import CustomReportModal from './CustomReportModal';

import Chart from 'react-apexcharts'

const ScanDetails = () => {
  const location = useLocation();
  
  const [searchText, setSearchText] = useState('');
  const [filterList, setFilterList] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    packageNames: [],
    issueTitles: [],
    severities: [],
    severityTypes: [],
    licenses: [],
  });
  const [severityCounts, setSeverityCounts] = useState([0,0,0,0]);
  
  const [searchInput, setSearchInput] = useState('');
  const prevSearchInput = useRef(searchInput);

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()
  const [scanId, setScanId] = useState('')
  const [userId, setUserId] = useState('')
  const [scaScan, setScaScan] = useState(null)
  const [onLoading, setOnLoading] = useState(true)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [customReportModalOpen, setCustomReportModalOpen] = useState(false)
  const [currentVulnerability, setCurrentVulnerability] = React.useState(null)

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [exportingPDF, setExportingPDF] = useState(false)

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '20%',
      width: '60%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow',
      zIndex: 1000,
    },
  }

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  }

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    window.scrollTo(0, 0)
    setOnLoading(true)
 
    var arr = location.search.split('=')
    var theScanId = arr[1]
    setScanId(theScanId)
 
    loadScanDetails(theScanId, 0, rowsPerPage, '', {})
 
    var user = JSON.parse(localStorage.getItem('ASIUser'))
    setUserId(user._id)
  }, [])
 
  useEffect(() => {
    if (prevSearchInput.current && searchInput.length === 0) {
      loadScanDetails(
        scanId,
        0,
        rowsPerPage,
        '',
        filterList
      );
    }
    prevSearchInput.current = searchInput;
  }, [searchInput]);
 
  const loadScanDetails = async (theScanId, page, rowsPerPage, searchText, filterList) => {
    setOnLoading(true)
    try {
      const queryParams = new URLSearchParams();
      
      if (searchText) {
        queryParams.append('search', searchText);
      }
      
      if (Object.keys(filterList).length > 0) {
        queryParams.append('filterList', JSON.stringify(filterList));
      }
      
      const queryString = queryParams.toString();
      const url = `/api/v1/sca-scans/details/${theScanId}/${page}/${rowsPerPage}${queryString ? `?${queryString}` : ''}`;
 
      const token = localStorage.getItem('ASIToken')
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
 
      setScaScan(response.data.scaScan)
      setCount(response.data.totalCount)
      setFilterOptions(response.data.filterOptions)
      setSeverityCounts(response.data.severityCounts)
    } catch (error) {
      console.error('Error loading scan details:', error)
    } finally {
      setOnLoading(false)
    }
  }
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
 
  const handleClose = () => {
    setAnchorEl(null)
  }
 
  const handleMenuItemClick = (action) => {
    setExportingPDF(true)
    switch (action) {
      case 'summary':
        downloadSummaryReport()
        break
      case 'detailed':
        downloadDetailedReport()
        break
      case 'custom':
        downloadCustomReport()
        break
      case 'xml':
        exportToXML()
        break
      case 'json':
        exportToJSON()
        break
      default:
        break
    }
    setExportingPDF(false)
    handleClose()
  }
 
  const downloadSummaryReport = () => {
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/summary/none'
    window.open(urlToOpen, '_blank')
  }
 
  const downloadDetailedReport = () => {
    downloadPDF()
  }
 
  const downloadCustomReport = () => {
    setCustomReportModalOpen(true)
  }
 
  const downloadPDF = async () => {
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/full/none'
    window.open(urlToOpen, '_blank')
  }
 
  const handleGenerateCustomReport = (selectedOptions) => {
    const optionsString = JSON.stringify(selectedOptions)
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/custom/' + optionsString
    window.open(urlToOpen, '_blank')
  }

  const exportToXML = () => {
    const jsonToXml = (obj, rootName) => {
      let xml = ''
      if (rootName) {
        xml += `<${rootName}>`
      }
 
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          xml += `<${key}>`
          for (const item of obj[key]) {
            if (typeof item === 'object') {
              xml += jsonToXml(item, 'item')
            } else {
              xml += `<item>${item}</item>`
            }
          }
          xml += `</${key}>`
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          xml += jsonToXml(obj[key], key)
        } else {
          xml += `<${key}>${obj[key]}</${key}>`
        }
      }
 
      if (rootName) {
        xml += `</${rootName}>`
      }
      return xml
    }
 
    const formatXml = (xml) => {
      const PADDING = ' '.repeat(2)
      const reg = /(>)(<)(\/*)/g
      let pad = 0
      xml = xml.replace(reg, '$1\r\n$2$3')
      return xml.split('\r\n').map((node) => {
        let indent = 0
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0
        } else if (node.match(/^<\/\w/)) {
          if (pad !== 0) {
            pad -= 1
          }
        } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
          indent = 1
        } else {
          indent = 0
        }
 
        const padding = PADDING.repeat(pad)
        pad += indent
        return padding + node
      }).join('\r\n')
    }
 
    const xmlString = jsonToXml(scaScan, 'SCAScan')
    const formattedXml = formatXml(xmlString)
    const blob = new Blob([formattedXml], { type: 'application/xml' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'SCA_Scan_Report_' + scaScan._id + '.xml'
    link.click()
    URL.revokeObjectURL(url)
  }
 
  const exportToJSON = () => {
    const jsonString = JSON.stringify(scaScan, null, 2)
    const blob = new Blob([jsonString], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'SCA_Scan_Report_' + scaScan._id + '.json'
    link.click()
    URL.revokeObjectURL(url)
  }
 
  const openRemediationModal = async (value) => {
    setCurrentVulnerability(value)
    setModalIsOpen(true)
  }
 
  const closeModal = async () => {
    setModalIsOpen(false)
  }
 
  const goBack = async () => {
    navigate('/sca-scans')
  }
 
  const columns = [
    {
      label: "#",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "Package Name",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.packageNames,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Package Name'] || []
      }
    },
    {
      label: "Issue Title",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.issueTitles,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Issue Title'] || []
      }
    },

    {
      label: "Reported At",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "Severity",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.severities,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Severity'] || [],
        customBodyRender: (value, tableMeta, updateValue) => {
          let bgColor
          let theColor
 
          if (value === 'CRITICAL') {
            bgColor = '#990000'
            theColor = '#fff'
          } else if (value === 'HIGH') {
            bgColor = '#FF6600'
            theColor = '#fff'
          } else if (value === 'MEDIUM' || value === 'MODERATE') {
            bgColor = '#FFCC00'
            theColor = 'black'
          } else if (value === 'LOW') {
            bgColor = '#669933'
            theColor = '#fff'
          }
 
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <text style={{
                padding: 5,
                backgroundColor: bgColor,
                color: theColor,
                width: 120,
                textAlign: 'center',
                borderRadius: 10,
                fontSize: 13
              }}>{value === 'MODERATE' ? 'MEDIUM' : value}</text>
            </div>
          )
        }
      }
    },
    {
      label: "CWE",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#fff',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "CVE",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#fff',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "PCI DSS Violations",
      options: {
        filter: false,
        download: true,
        display: scaScan && scaScan.application.pciDSSEnabled ? true : false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value !== '---') {
            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }}>
                {value.map(val => (
                  <text style={{
                    padding: 5,
                    margin: 5,
                    backgroundColor: '#fffde7',
                    color: '#000',
                    fontWeight: 'bold',
                    width: 120,
                    textAlign: 'center',
                    borderRadius: 10,
                    fontSize: 13
                  }}>{val}</text>
                ))}
              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }}>
                ---
              </div>
            )
          }
        }
      }
    },
    {
      label: "Severity Type",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.severityTypes,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['Severity Type'] || []
      }
    },
    {
      label: "Severity Score",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "License",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.licenses,
          logic: (value, filters) => {
            if (filters.length === 0) return false;
            return !filters.includes(value);
          }
        },
        filterList: filterList['License'] || []
      }
    },
    {
      label: "Latest Available Version",
      options: {
        filter: false,
        download: true,
      }
    },
    {
      label: "References",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }}>
              <CButton color="primary" variant="outline"
                onClick={() => openRemediationModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>
                View References
              </CButton>
            </div>
          )
        }
      }
    },
  ]
 
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
    }
  })
  const options = {
    filterType: "multiselect",
    responsive: "stacked",
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '10px',
          padding: '8px 16px'
        }}>
          <input
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearchText(searchInput);
                setPage(0);
                loadScanDetails(
                  scanId,
                  0,
                  rowsPerPage,
                  searchInput,
                  filterList
                );
              }
            }}
            placeholder="Search vulnerabilities..."
            style={{
              padding: '8px 12px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              width: '300px',
              fontSize: '14px',
              color: '#000',
            }}
          />
          <button
            onClick={() => {
              setSearchText(searchInput);
              setPage(0);
              loadScanDetails(
                scanId,
                0,
                rowsPerPage,
                searchInput,
                filterList
              );
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#1976d2',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Search
          </button>
          {searchInput && (
            <button
              onClick={() => {
                setSearchInput('');
                setSearchText('');
                setPage(0);
                loadScanDetails(
                  scanId,
                  0,
                  rowsPerPage,
                  '',
                  filterList
                );
              }}
              style={{
                padding: '8px 16px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#d32f2f',
                color: 'white',
                cursor: 'pointer',
                fontSize: '14px'
              }}
            >
              Clear
            </button>
          )}
        </div>
      );
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          loadScanDetails(
            scanId,
            tableState.page,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;
 
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;
 
        case 'filterChange':
          const newFilterList = {};
          tableState.filterList.forEach((filter, index) => {
            if (filter && filter.length) {
              newFilterList[columns[index].label] = filter;
            }
          });
          setFilterList(newFilterList);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            newFilterList
          );
          break;
 
        case 'resetFilters':
          setFilterList({});
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            {}
          );
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: onLoading ? 'Loading data...' : 'No vulnerabilities found',
      }
    },
    filter: true,
    search: true,
    searchOpen: true,
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    elevation: 0,
  }
 
  let tableData = [];
  if (scaScan && scaScan.vulnerabilities) {
    tableData = scaScan.vulnerabilities.map((vuln, index) => {
      const severity = vuln.severity === 'MODERATE' ? 'MEDIUM' : vuln.severity;
      
      return [
        ((page) * rowsPerPage) + (index + 1),
        vuln.packageName,
        vuln.issueTitle,
        vuln.reportdAt,
        severity,
        vuln.cwe?.length > 0 ? vuln.cwe : '---',
        vuln.cve?.length > 0 ? vuln.cve : '---',
        vuln.pciViolations?.length > 0 ? vuln.pciViolations : '---',
        vuln.severityType,
        vuln.severityScore,
        vuln.license,
        vuln.latestVersion,
        vuln
      ];
    });
  }
 
  const labelsArray = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
  const bgColorArray = ['#990000', '#FF6600', '#FFCC00', '#669933'];
 
  const vulnDistrochartOptions = {
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
      onItemHover: {
        highlightDataSeries: false
      }
    },
  };
 
  const vulnDistroChartSeries = severityCounts;
 
  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden' }}>
      <>
        {onLoading ? (
          <div style={{
            width: '80%',
            marginLeft: '10%',
            marginRight: '10%',
            marginTop: '2%'
          }}>
            <ShimmerTable row={5} col={10} />
          </div>
        ) : (
          <div style={{
            width: '100%',
            marginLeft: '0%',
            marginRight: '0%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '2%',
            background: 'linear-gradient(135deg, #161c2a, #1a2130)',
            padding: 20,
            borderRadius: 15
          }}>
            <div style={{ marginBottom: '2rem' }}>
              <h2>{scaScan.projectName}</h2>
              <hr />
 
              <table style={{ width: 600 }}>
                <tbody>
                  <tr>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                      <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan ID</h6>
                    </td>
                    <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                      {scanId}
                    </td>
                  </tr>
                  <tr>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                     <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Name</h6>
                   </td>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                     {scaScan.scanName}
                   </td>
                 </tr>

                 <tr>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                     <h6 style={{ fontWeight: 'bold', color: '#000' }}>Application Name</h6>
                   </td>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                     {scaScan.application.name}
                   </td>
                 </tr>

                 <tr>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                     <h6 style={{ fontWeight: 'bold', color: '#000' }}>Vulnerabilities</h6>
                   </td>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                     {count}
                   </td>
                 </tr>

                 <tr>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                     <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Started At</h6>
                   </td>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                     {(new Date(scaScan.createdAt)).toLocaleDateString('en-US')} - {(new Date(scaScan.createdAt)).toLocaleTimeString('en-US')}
                   </td>
                 </tr>

                 <tr>
                   <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                     <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Status</h6>
                   </td>
                   <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>
                     {scaScan.status ? scaScan.status.toUpperCase() : '---'}
                   </td>
                 </tr>

                 {scaScan.scanCompletedAt && (
                   <tr>
                     <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                       <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Completed At</h6>
                     </td>
                     <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>
                       {(new Date(scaScan.scanCompletedAt)).toLocaleDateString('en-US')} - {(new Date(scaScan.scanCompletedAt)).toLocaleTimeString('en-US')}
                     </td>
                   </tr>
                 )}
               </tbody>
             </table>
           </div>

           <div style={{ display: 'flex', flexDirection: 'column' }}>
             <CButton
               onClick={goBack}
               style={{
                 width: 300,
                 marginBottom: '2%',
                 borderWidth: 1,
                 fontSize: 20,
                 borderColor: '#ffffff',
                 color: '#ffffff',
                 background: 'transparent'
               }}
               color="primary"
               className="px-3"
             >
               <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Scans List
             </CButton>

             {scaScan.status === 'Completed' && (
               <CButton
                 onClick={handleClick}
                 style={{
                   width: 300,
                   marginBottom: '2%',
                   borderWidth: 0,
                   fontSize: 20,
                   marginTop: 10,
                   background: '#e50202'
                 }}
                 color="primary"
                 className="px-3"
               >
                 {exportingPDF ? (
                   <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                 ) : (
                   <>
                     Download Report <FaCaretDown size={25} style={{ color: '#ffffff', marginLeft: 10 }} />
                   </>
                 )}
               </CButton>
             )}
             <Menu
               id="simple-menu"
               anchorEl={anchorEl}
               keepMounted
               open={Boolean(anchorEl)}
               onClose={handleClose}
               style={{ width: '100%', color: 'white' }}
             >
               <MenuItem onClick={() => handleMenuItemClick('summary')} style={{ width: '100%', color: 'white' }}>Summary Report (PDF)</MenuItem>
               <MenuItem onClick={() => handleMenuItemClick('detailed')} style={{ width: '100%', color: 'white' }}>Detailed Report (PDF)</MenuItem>
               <MenuItem onClick={() => handleMenuItemClick('custom')} style={{ width: '100%', color: 'white' }}>Custom Report (PDF)</MenuItem>
               <MenuItem onClick={() => handleMenuItemClick('xml')} style={{ width: '100%', color: 'white' }}>Export to XML</MenuItem>
               <MenuItem onClick={() => handleMenuItemClick('json')} style={{ width: '100%', color: 'white' }}>Export to JSON</MenuItem>
             </Menu>
           </div>
         </div>
       )}

       <div style={{
         width: '100%',
         marginLeft: '0%',
         marginRight: '0%',
         display: 'flex',
         flexDirection: 'column',
         marginTop: '2%',
         background: 'linear-gradient(135deg, #161c2a, #1a2130)',
         padding: 20,
         borderRadius: 15
       }}>
         <h4 style={{ marginTop: 30 }}>Severity Scoring</h4>
         <hr />

         <span>
           <text style={{ fontWeight: 'bold', backgroundColor: '#990000', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>
             CRITICAL
           </text>
           Vulnerabilities that can be exploited remotely, leading to immediate and widespread impact on the confidentiality,
           integrity, and availability of systems or data.
         </span>

         <span style={{ marginTop: 15 }}>
           <text style={{ fontWeight: 'bold', backgroundColor: '#FF6600', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>
             HIGH
           </text>
           Vulnerabilities that can be exploited but require some form of user interaction or other
           preconditions to be met, potentially resulting in significant impact on system or data.
         </span>

         <span style={{ marginTop: 15 }}>
           <text style={{ fontWeight: 'bold', backgroundColor: '#FFCC00', color: '#000', padding: 5, borderRadius: 5, marginRight: 20 }}>
             MEDIUM
           </text>
           Vulnerabilities that could result in a compromise of system or data security,
           but require more complex exploitation techniques or have limited impact.
         </span>

         <span style={{ marginTop: 15 }}>
           <text style={{ fontWeight: 'bold', backgroundColor: '#669933', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>
             LOW
           </text>
           Vulnerabilities that have a low likelihood of being exploited or have minimal impact on system or data security.
         </span>
       </div>

       {onLoading ? (
         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 50 }}>
           <ShimmerTable row={8} col={10} />
         </div>
       ) : (
         <>
           {scaScan.vulnerabilities.length > 0 && (
             <div style={{
               width: '33%',
               marginLeft: '33%',
               marginRight: '33%',
               display: 'flex',
               flexDirection: 'column',
               marginTop: '2%',
               background: 'linear-gradient(135deg, #161c2a, #1a2130)',
               padding: 20,
               borderRadius: 15
             }}>
               <h4 style={{ color: '#fff', textAlign: 'center' }}>Severity Distribution</h4>
               <hr />
               <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} type="pie" />
             </div>
           )}
         </>
       )}
       <div style={{
         width: '100%',
         marginLeft: '0%',
         marginRight: '0%',
         display: 'flex',
         flexDirection: 'column',
         marginTop: '2%'
       }}>
         {onLoading ? (
           <ShimmerTable row={8} col={10} />
         ) : (
           <>
             {tableData.length > 0 && (
               <ThemeProvider theme={getMuiTheme()}>
                 <MUIDataTable
                   style={{ height: "57vh" }}
                   data={tableData}
                   columns={columns}
                   options={options}
                 />
               </ThemeProvider>
             )}
           </>
         )}
       </div>

       <Modal
         isOpen={modalIsOpen}
         onRequestClose={closeModal}
         style={customStyles}
         contentLabel="References"
       >
         <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeModal}>
           <AiFillCloseCircle size={30} color={'#000'} />
         </button>

         {currentVulnerability && (
           <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>
             <h5 style={{ color: '#000' }}>
               <strong>Issue</strong>: {currentVulnerability.issueTitle}
             </h5>
             <h5 style={{ color: '#000' }}>
               <strong>Severity</strong>: {currentVulnerability.severity === 'MODERATE' ? 'MEDIUM' : currentVulnerability.severity}
             </h5>

             <hr />

             <h5 style={{ color: '#000' }}>References</h5>
             <hr />

             <div style={{ backgroundColor: '#ebedef', padding: 10, marginTop: 10, borderRadius: 15 }}>
               {currentVulnerability.sources && currentVulnerability.sources.length > 0 && (
                 <div>
                   <h6 style={{ color: '#333', fontSize: 14, fontWeight: 'normal', marginTop: 5 }}>Sources:</h6>
                   <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                     {currentVulnerability.sources.map((source, index) => (
                       <li key={index} style={{ marginBottom: 5 }}>
                         <a target="_blank" href={source} style={{ color: 'blue' }}>{source}</a>
                       </li>
                     ))}
                   </ul>
                 </div>
               )}
             </div>
           </div>
         )}
       </Modal>

       <CustomReportModal
         open={customReportModalOpen}
         onClose={() => setCustomReportModalOpen(false)}
         onGenerateReport={handleGenerateCustomReport}
       />
     </>
   </div>
 );
};

export default ScanDetails;