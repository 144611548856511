import React from 'react';

const CircleCIIntegration = () => {
  return (
    <div>
      <h3 className="darkText">CircleCI Integration Instructions for SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure CircleCI Project</h4>
      <ol>
        <li>Go to your CircleCI project settings.</li>
        <li>Navigate to <strong>Environment Variables</strong> under the <strong>Project Settings</strong> menu.</li>
        <li>Add the following environment variables:
          <ul>
            <li><strong>VERCEL_TOKEN</strong> (if deploying with Vercel)</li>
          </ul>
        </li>
      </ol>

      <h4 className="darkText">Step 2: Create CircleCI Configuration File</h4>
      <p>Create a file named <code>.circleci/config.yml</code> in your repository with the following content:</p>

      <pre>
        <code>
{`version: 2.1

jobs:
  sast-sca-and-deploy:
    docker:
      - image: cimg/node:16.0  # Adjust this to your preferred Node.js version
    steps:
      - checkout
      
      - run:
          name: Define Environment Variables
          command: |
            echo 'export INTRUCEPT_CLIENT_ID="123e4567-e89b-12d3-a456-426614174001"' >> $BASH_ENV
            echo 'export INTRUCEPT_CLIENT_SECRET="7a91d1c9-2583-4ef6-8907-7c974f1d6a0e"' >> $BASH_ENV
            echo 'export INTRUCEPT_APPLICATION_ID="65e07ecef30e83d820b00d55"' >> $BASH_ENV
            source $BASH_ENV

      - run:
          name: Prepare for scans
          command: |
            sudo apt-get update
            sudo apt-get install -y zip curl jq
            zip -r project.zip . -x '*.git*'

      - run:
          name: Perform SAST scan
          command: |
            echo "Starting SAST Scan..."
            set +e # Allow pipeline to continue even if this command fails
            SAST_RESPONSE=$(curl -X POST \\
              -H "Client-ID: $INTRUCEPT_CLIENT_ID" \\
              -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \\
              -F "projectZipFile=@project.zip" \\
              -F "applicationId=$INTRUCEPT_APPLICATION_ID" \\
              -F "scanName=React App SAST Scan from CircleCI" \\
              -F "language=javascript" \\
              https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
            
            SAST_CAN_PROCEED=$(echo $SAST_RESPONSE | jq -r '.canProceed')
            SAST_VULNS_TABLE=$(echo $SAST_RESPONSE | jq -r '.vulnsTable')
            
            echo "Vulnerabilities found during SAST:"
            echo "$SAST_VULNS_TABLE"
            
            if [ "$SAST_CAN_PROCEED" != "true" ]; then
              echo "SAST scan failed. Continuing to SCA scan, but deployment may be cancelled."
            fi
            set -e

      - run:
          name: Perform SCA scan
          command: |
            echo "Starting SCA Scan..."
            SCA_RESPONSE=$(curl -X POST \\
              -H "Client-ID: $INTRUCEPT_CLIENT_ID" \\
              -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \\
              -F "projectZipFile=@project.zip" \\
              -F "applicationId=$INTRUCEPT_APPLICATION_ID" \\
              -F "scanName=React App SCA Scan from CircleCI" \\
              -F "language=javascript" \\
              https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
            
            SCA_CAN_PROCEED=$(echo $SCA_RESPONSE | jq -r '.canProceed')
            SCA_VULNS_TABLE=$(echo $SCA_RESPONSE | jq -r '.vulnsTable')
            
            echo "Vulnerabilities found during SCA:"
            echo "$SCA_VULNS_TABLE"
            
            if [ "$SCA_CAN_PROCEED" != "true" ]; then
              echo "SCA scan failed. Deployment cancelled."
              exit 1
            fi

      - run:
          name: Deploy to Vercel
          command: |
            if [ "\${CIRCLE_BRANCH}" == "main" ]; then
              npm install -g vercel
              vercel --token="\${VERCEL_TOKEN}" --prod --confirm
            else
              echo "Not deploying as this is not the main branch"
            fi

workflows:
  version: 2
  sast-sca-and-deploy:
    jobs:
      - sast-sca-and-deploy:
          filters:
            branches:
              only: main  # Adjust this to your main branch name`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Update Configuration Details</h4>
      <p>In the CircleCI configuration file:</p>
      <ol>
        <li>Replace the placeholder values for <code>INTRUCEPT_CLIENT_ID</code>, <code>INTRUCEPT_CLIENT_SECRET</code>, and <code>INTRUCEPT_APPLICATION_ID</code> with your actual Intrucept credentials.</li>
        <li>Adjust the Node.js version in the Docker image if needed.</li>
        <li>Modify the 'language' parameter in the API calls if your project uses a different primary language.</li>
        <li>If you're not using Vercel for deployment, replace the deployment step with your own deployment process.</li>
      </ol>

      <h4 className="darkText">Explanation of the CircleCI Configuration</h4>
      <p>This CircleCI configuration does the following:</p>
      <ol>
        <li>Defines environment variables for Intrucept credentials.</li>
        <li>Prepares the environment by installing necessary tools (zip, curl, jq).</li>
        <li>Performs SAST scan using the Intrucept API:
          <ul>
            <li>Displays the vulnerabilities table from the SAST scan.</li>
            <li>Allows the pipeline to continue even if SAST scan fails.</li>
          </ul>
        </li>
        <li>Performs SCA scan using the Intrucept API:
          <ul>
            <li>Displays the vulnerabilities table from the SCA scan.</li>
            <li>Fails the pipeline if SCA scan detects critical issues.</li>
          </ul>
        </li>
        <li>Deploys the application using Vercel if on the main branch and both scans pass.</li>
      </ol>

      <p><strong>Note:</strong> Ensure that you have added the required environment variables (especially VERCEL_TOKEN if using Vercel) to your CircleCI project settings before running this pipeline.</p>
    </div>
  );
};

export default CircleCIIntegration;