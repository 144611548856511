import React from 'react';

const TeamCityIntegration = () => {
  return (
    <div className="teamcity-integration-guide">
      <h2 className="darkText">TeamCity Integration Guide for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Step 1: Connect GitHub Project</h3>
        <ol>
          <li>In TeamCity, go to your project settings.</li>
          <li>Click on "VCS Roots" and add a new VCS root for your GitHub repository.</li>
          <li>Configure the VCS root with your GitHub repository URL and authentication details.</li>
          <li>Create a new build configuration for your project.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 2: Add Build Steps</h3>
        <p>Add the following two build steps to your build configuration:</p>
        <table>
          <thead>
            <tr>
              <th>Build Step</th>
              <th>Parameters</th>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SAST Scan</td>
              <td>
                <strong>Runner type:</strong> Command Line<br/>
                <strong>Custom script:</strong> chmod +x sast_scan.sh && ./sast_scan.sh
              </td>
             
            </tr>
            <tr>
              <td>SCA Scan</td>
              <td>
                <strong>Runner type:</strong> Command Line<br/>
                <strong>Custom script:</strong> chmod +x sca_scan.sh && ./sca_scan.sh
              </td>
              
            </tr>
          </tbody>
        </table>
      </section>

      <section>
        <h3 className="darkText">Step 3: Create Script Files</h3>
        <p>In the root of your project repository, create two script files:</p>

        <h4>1. sast_scan.sh</h4>
        <pre>
          <code>
{`#!/bin/bash
set -e
# Create ZIP file for SAST Scan
zip -r project.zip . -x '*.git*'
# Perform SAST Scan
RESPONSE=$(curl -X POST \\
  -H "Client-ID: 123e4567-e89b-12d3-a456-426614174001" \\
  -H "Client-Secret: 7a91d1c9-2583-4ef6-8907-7c974f1d6a0e" \\
  -F "projectZipFile=@project.zip" \\
  -F "applicationId=65e07ecef30e83d820b00d55" \\
  -F "scanName=New SAST Scan from TeamCity" \\
  -F "language=python" \\
  https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
# Use Python to parse and display JSON
python3 - <<EOF
import json
import sys
try:
    data = json.loads('''$RESPONSE''')
    print("SAST Scan Results:")
    print(f"Can Proceed: {data.get('canProceed', 'N/A')}")
    print("\\nVulnerabilities Table:")
    vulns_table = data.get('vulnsTable', 'No vulnerabilities table found')
    print(json.dumps(vulns_table, indent=2))
    
    if data.get('canProceed') == False:
        print("\\nCritical vulnerabilities found. Please review the scan results.")
    else:
        print("\\nNo critical vulnerabilities detected.")
except json.JSONDecodeError:
    print("Error: Invalid JSON response")
    print("Raw response:", '''$RESPONSE''')
except Exception as e:
    print(f"Error: {str(e)}")
    print("Raw response:", '''$RESPONSE''')
EOF
# Clean up
rm project.zip
# Always exit with 0 to not fail the build
exit 0`}
          </code>
        </pre>

        <h4>2. sca_scan.sh</h4>
        <pre>
          <code>
{`#!/bin/bash
set -e
# Get the current directory name
CURRENT_DIR=$(basename "$PWD")
# Create a temporary directory
TEMP_DIR=$(mktemp -d)
# Copy current directory contents to a subdirectory in the temp directory
cp -R . "$TEMP_DIR/$CURRENT_DIR"
# Create ZIP file for SCA Scan
(cd "$TEMP_DIR" && zip -r "../$CURRENT_DIR.zip" "$CURRENT_DIR" -x '*.git*')
# Move the ZIP file to the current directory
mv "$TEMP_DIR/../$CURRENT_DIR.zip" .
# Clean up the temporary directory
rm -rf "$TEMP_DIR"
# Verify the ZIP file exists
if [ ! -f "$CURRENT_DIR.zip" ]; then
    echo "Error: ZIP file not created successfully."
    exit 1
fi
# Perform SCA Scan
RESPONSE=$(curl -X POST \\
  -H "Client-ID: 123e4567-e89b-12d3-a456-426614174001" \\
  -H "Client-Secret: 7a91d1c9-2583-4ef6-8907-7c974f1d6a0e" \\
  -F "projectZipFile=@$CURRENT_DIR.zip" \\
  -F "applicationId=65e07ecef30e83d820b00d55" \\
  -F "scanName=New SCA Scan from TeamCity" \\
  -F "language=java" \\
  https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
# Use Python to parse and display JSON
python3 - <<EOF
import json
import sys
def print_table(data):
    if not data:
        print("No vulnerability data available.")
        return
    
    # Assuming data is a list of dictionaries
    keys = data[0].keys()
    
    # Print header
    for key in keys:
        print(f"{key:<20}", end="")
    print()
    print("-" * (20 * len(keys)))
    
    # Print rows
    for row in data:
        for key in keys:
            print(f"{str(row.get(key, '')):<20}", end="")
        print()
try:
    data = json.loads('''$RESPONSE''')
    print("SCA Scan Results:")
    print(f"Can Proceed: {data.get('canProceed', 'N/A')}")
    
    print("\\nVulnerabilities Table:")
    vulns_table = data.get('vulnsTable')
    if isinstance(vulns_table, str):
        # If vulnsTable is a string, try to parse it as JSON
        try:
            vulns_table = json.loads(vulns_table)
        except json.JSONDecodeError:
            print("Error: Unable to parse vulnerabilities table.")
            vulns_table = None
    
    if vulns_table:
        print_table(vulns_table)
    else:
        print("No vulnerabilities table found or table is empty.")
    
    if data.get('canProceed') == False:
        print("\\nCritical vulnerabilities found. Please review the scan results.")
    else:
        print("\\nNo critical vulnerabilities detected.")
except json.JSONDecodeError:
    print("Error: Invalid JSON response")
    print("Raw response:", '''$RESPONSE''')
except Exception as e:
    print(f"Error: {str(e)}")
    print("Raw response:", '''$RESPONSE''')
EOF
# Clean up
rm "$CURRENT_DIR.zip"
# Always exit with 0 to not fail the build
exit 0`}
          </code>
        </pre>
      </section>

      <section>
        <h3 className="darkText">Step 4: Configure Build Triggers</h3>
        <p>Set up build triggers in TeamCity to run your build configuration when changes are pushed to your GitHub repository.</p>
      </section>

      <section>
        <h3 className="darkText">Step 5: Run the Build</h3>
        <ol>
          <li>In TeamCity, navigate to your build configuration.</li>
          <li>Click "Run" to start the build process manually, or wait for it to be triggered by a code push.</li>
          <li>TeamCity will execute the SAST and SCA scans as part of the build process.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 6: View Results</h3>
        <p>After the build completes:</p>
        <ol>
          <li>Go to the build results page in TeamCity.</li>
          <li>Check the build log for the output of the SAST and SCA scans.</li>
          <li>Review any vulnerabilities or issues reported by the scans.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Important Notes</h3>
        <ul>
          <li>Ensure that the TeamCity agent has the necessary permissions to execute the shell scripts and make HTTP requests.</li>
          <li>Replace the placeholder values for Client-ID, Client-Secret, and applicationId with your actual Intrucept credentials.</li>
          <li>Adjust the 'language' parameter in the API calls if your project uses a different primary language.</li>
          <li>Consider using TeamCity's built-in secret management for storing sensitive information like API credentials.</li>
        </ul>
      </section>
    </div>
  );
};

export default TeamCityIntegration;