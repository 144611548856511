import React, { useState } from 'react';
import { Modal, Box, Typography, FormGroup, Button } from '@mui/material';

const CustomReportModal = ({ open, onClose, onGenerateReport }) => {
  const [selectedOptions, setSelectedOptions] = useState({
    executiveSummary: true,
    summaryOfChecks: true,
    scanOverview: true,
    scanOverviewFindings: true,
    methodology: true,
  });

  const handleChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleGenerateReport = () => {
    onGenerateReport(selectedOptions);
    console.log('selectedOptions:', selectedOptions);
    onClose();
  };

  // Check if any option is selected
  const isAnyOptionSelected = Object.values(selectedOptions).some((value) => value);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-report-modal-title"
      aria-describedby="custom-report-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          color: '#000', // Text color for modal content
        }}
      >
        <Typography id="custom-report-modal-title" variant="h6" component="h2" style={{ color: '#000' }}>
          Custom Report Options
        </Typography>
        <FormGroup style={{ marginTop: 30 }}>
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={selectedOptions.executiveSummary}
              onChange={handleChange}
              name="executiveSummary"
              style={{ marginRight: 8, width: 20, height: 20 }}
            />
            Executive Summary
          </label>
          <br />
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={selectedOptions.summaryOfChecks}
              onChange={handleChange}
              name="summaryOfChecks"
              style={{ marginRight: 8, width: 20, height: 20 }}
            />
            Summary of Checks by Status
          </label>
          <br />
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={selectedOptions.scanOverview}
              onChange={handleChange}
              name="scanOverview"
              style={{ marginRight: 8, width: 20, height: 20 }}
            />
            Scan Overview
          </label>
          <br />
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={selectedOptions.scanOverviewFindings}
              onChange={handleChange}
              name="scanOverviewFindings"
              style={{ marginRight: 8, width: 20, height: 20 }}
            />
            Scan Overview Findings
          </label>
          <br />
          <label style={{ display: 'flex', flexDirection: 'row' }}>
            <input
              type="checkbox"
              checked={selectedOptions.methodology}
              onChange={handleChange}
              name="methodology"
              style={{ marginRight: 8, width: 20, height: 20 }}
            />
            Methodology
          </label>
        </FormGroup>

        <Button
          onClick={handleGenerateReport}
          color="primary"
          disabled={!isAnyOptionSelected} // Disable button if no options are selected
          style={{ marginTop: 20, paddingLeft: 10, textAlign: 'center', backgroundColor: '#e50202', color: '#fff', fontSize: 12,width:300  }}
        >
          Generate Report
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomReportModal;
