import React, { useState, useEffect, useRef } from "react";
import { CButton, CToast, CToastBody } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CSSProperties } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineSecurityScan } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

const QuickScans = () => {
 const navigate = useNavigate()

 const [searchText, setSearchText] = useState('');
 const [filterList, setFilterList] = useState({});
 const [filterOptions, setFilterOptions] = useState({
   applications: [],
   primaryUrls: [],
 });
 
 const [searchInput, setSearchInput] = useState('');
 const prevSearchInput = useRef(searchInput);

 const [dastScans, setDastScans] = useState([])
 const [onLoading, setOnLoading] = useState(false);  

 const [scanToDelete, setScanToDelete] = useState(null);
 const [modalIsOpen, setModalIsOpen] = React.useState(false);

 const [page, setPage] = useState(0);
 const [count, setCount] = useState(0);
 const [rowsPerPage, setRowsPerPage] = useState(10);

 const customStyles = {
   content: {
     top: '30%',
     left: '25%',
     width: '50%',
     right: 'auto',
     bottom: 'auto',
     height: '25%',
     backgroundColor: '#ffffff',
     borderRadius: 15,
     borderColor: 'ffffff'
   },
 };

 useEffect(() => {
   if (prevSearchInput.current && searchInput.length === 0) {
     fetchDASTScans(
       true,
       0,
       rowsPerPage,
       '',
       filterList
     );
   }
   prevSearchInput.current = searchInput;
 }, [searchInput]);

 const handleClick = (user) => {
   setScanToDelete(user);
   setModalIsOpen(true);
 };

 const handleConfirmation = (confirmed) => {
   if (confirmed) {
     deleteFunction(scanToDelete);
   }
   setModalIsOpen(false);
 };

 const deleteFunction = (scan) => {
   deleteScan(scan)
 };

 const closeModal = async () => {
   setModalIsOpen(false);
 };

 const deleteScan = async (id) => {
   const bearerToken = localStorage.getItem('ASIToken');

   try {
     const response = await axios.delete(`api/v1/dast-scans/${id}`, {
       headers: {
         Authorization: `Bearer ${bearerToken}`,
       },
     });

     if (response.data.hasOwnProperty('error')) {
       toast.error(response.data.error, {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
       });
     } else {
       toast('Scan deleted', {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
       });

       fetchDASTScans(true, page, rowsPerPage, searchText, filterList);
     }
   } catch (error) {
     console.error('Error:', error);
   }
 }

 const toaster = useRef()
 const exampleToast = (
   <CToast>
     <CToastBody>Success</CToastBody>
   </CToast>
 )

 const override: CSSProperties = {
   display: "block",
   margin: "0 auto",
   borderColor: "red",
 };

 const isFirstTime = useRef(true);

 const fetchDASTScans = async (isFirstTime, page, rowsPerPage, searchText, filterList) => {
   if (isFirstTime) {
     setOnLoading(true);
   }
 
   try {
     const queryParams = new URLSearchParams();
     
     if (searchText) {
       queryParams.append('search', searchText);
     }
     
     if (Object.keys(filterList).length > 0) {
       queryParams.append('filterList', JSON.stringify(filterList));
     }
     
     const queryString = queryParams.toString();
     const url = `/api/v1/dast-scans/all/${page}/${rowsPerPage}${queryString ? `?${queryString}` : ''}`;

     const token = localStorage.getItem('ASIToken');
     const response = await axios.get(url, {
       headers: { Authorization: `Bearer ${token}` },
     });
 
     setDastScans(response.data.dastScans);
     setCount(response.data.totalCount);
     setFilterOptions(response.data.filterOptions);
   } catch (error) {
     console.error('Error fetching DAST scans:', error);
   } finally {
     setOnLoading(false);
   }
 };

 useEffect(() => {
   const interval = setInterval(() => {
     fetchDASTScans(false, page, rowsPerPage, searchText, filterList);
   }, 20000);
   
   fetchDASTScans(true, 0, rowsPerPage, '', {});
   isFirstTime.current = false;

   return () => clearInterval(interval);
 }, []);

 const [user, setUser] = useState({});
 
 useEffect(() => {
   getUserDetails();
 }, []);

 const getUserDetails = () => {
   setUser(JSON.parse(localStorage.getItem('ASIUser')));
 }

 const goToViewReport = async (scanId) => {
   navigate('/dast-scan-detail?scanId=' + scanId);
 };

 const columns = [
  {
    name: '#',
    label: '#',
    options: {
      sort: true,
      filter: false,
      sortDirection: 'desc',
    }
  },
  {
    label: "Scan Name",
    options: {
      filter: false,
      download: true,
    }
  },   
  {
    label: "Primary URL",
    options: {
      filter: true,
      download: true,
      filterOptions: {
        names: filterOptions.primaryUrls,
        logic: (value, filters) => {
          if (filters.length === 0) return false;
          return !filters.includes(value);
        }
      },
      filterList: filterList['Primary URL'] || []
    }
  },
  {
    label: "Application",
    options: {
      filter: true,
      download: true,
      filterOptions: {
        names: filterOptions.applications,
        logic: (value, filters) => {
          if (filters.length === 0) return false;
          return !filters.includes(value);
        }
      },
      filterList: filterList['Application'] || []
    }
  },
  {
    label: "URLs Scanned",
    options: {
      filter: false,
      download: true,
    }
  },   
  {
    label: "Issue Count",
    options: {
      filter: false,
      download: true,
    }
  },   
  {
    label: "Started At",
    options: {
      filter: false,
      download: true,
    }
  },   
  {
    label: "Completed At",
    options: {
      filter: false,
      download: true,
    }
  },   
  {
    label: "Status",
    options: {
      filter: false,
      download: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{
            display: "flex",
            alignItems: "center"
          }} >
            {value.status && value.status == 'Completed' ?
              <span style={{ fontSize: 12, color: '#fff', fontWeight: 'normal', backgroundColor:'#195905', padding:5, paddingTop:10,borderRadius:5 }}>
                {value.status && value.status.toUpperCase()}</span>
              :
              <text style={{ fontSize: 12, color: '#000', fontWeight: 'normal', backgroundColor:'#ffbf00', padding:5, paddingTop:10,borderRadius:5 }}>
                 {value.status && value.status.toUpperCase()}</text>
            }
          </div>
        )
      }
    }
  },
  {
    label: "View",
    options: {
      filter: false,
      download: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{
            display: "flex",
            alignItems: "center"
          }} >
            <CButton color="primary" variant="outline"
              onClick={() => goToViewReport(value._id)}
              className="m-2" style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color: '#fff', borderColor:'#fff', display:'flex', flexDirection:'row', alignItems:'center' }}>
                <FaEye style={{color:'white', marginRight:5}} size={15}/>
              View Report
            </CButton>      
          </div>
        )
      }
    }
  },
  {
    label: "Actions",
    options: {
      filter: false,
      download: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <div style={{
            display: "flex",
            alignItems: "center"
          }} >
            {(user.role == "Administrator" || user.role == "Application Manager") &&
              <CButton color="danger"
                onClick={() => handleClick(value)}
                variant="outline"
                className="m-1"
                style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
                 alignItems:'center', justifyContent:'center' }}>
                <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete
              </CButton>
            }
          </div>
        )
      }
    }
  },
];

const getMuiTheme = () => createTheme({
  components: {
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          textAlign: "left",
          '&:nth-child(1)': {
            width: 30,
          },
        }
      }
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          textAlign: "left",
          '&:nth-child(1)': {
            width: 30,
          },
        }
      }
    },
  }
})

const options = {
  filterType: "multiselect",
  responsive: "stacked",
  elevation: 0,
  filter: true,
  download: true,
  print: true,
  search: true,
  searchOpen: true,
  viewColumns: true,
  selectableRows: false,
  rowsPerPageOptions: [10, 20, 60, 100, 150],
  customSearchRender: (searchText, handleSearch, hideSearch, options) => {
    return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        gap: '10px',
        padding: '8px 16px'
      }}>
        <input
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setSearchText(searchInput);
              setPage(0);
              fetchDASTScans(
                true,
                0,
                rowsPerPage,
                searchInput,
                filterList
              );
            }
          }}
          placeholder="Search scans..."
          style={{
            padding: '8px 12px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            width: '300px',
            fontSize: '14px',
            color: '#000',
          }}
        />
        <button
          onClick={() => {
            setSearchText(searchInput);
            setPage(0);
            fetchDASTScans(
              true,
              0,
              rowsPerPage,
              searchInput,
              filterList
            );
          }}
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#1976d2',
            color: 'white',
            cursor: 'pointer',
            fontSize: '14px'
          }}
        >
          Search
        </button>
        {searchInput && (
          <button
            onClick={() => {
              setSearchInput('');
              setSearchText('');
              setPage(0);
              fetchDASTScans(
                true,
                0,
                rowsPerPage,
                '',
                filterList
              );
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '4px',
              border: 'none',
              backgroundColor: '#d32f2f',
              color: 'white',
              cursor: 'pointer',
              fontSize: '14px'
            }}
          >
            Clear
          </button>
        )}
      </div>
    );
  },
  onTableChange: (action, tableState) => {
    switch (action) {
      case 'changePage':
        setPage(tableState.page);
        fetchDASTScans(
          true,
          tableState.page,
          tableState.rowsPerPage,
          searchText,
          filterList
        );
        break;

      case 'changeRowsPerPage':
        setRowsPerPage(tableState.rowsPerPage);
        setPage(0);
        fetchDASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          filterList
        );
        break;

      case 'filterChange':
        const newFilterList = {};
        tableState.filterList.forEach((filter, index) => {
          if (filter && filter.length) {
            newFilterList[columns[index].label] = filter;
          }
        });
        setFilterList(newFilterList);
        setPage(0);
        fetchDASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          newFilterList
        );
        break;

      case 'resetFilters':
        setFilterList({});
        setPage(0);
        fetchDASTScans(
          true,
          0,
          tableState.rowsPerPage,
          searchText,
          {}
        );
        break;
    }
  },
  textLabels: {
    body: {
      noMatch: onLoading ? 'Loading data...' : 'No scans created yet',
    }
  },
  sortOrder: {
    name: 'ID',
    direction: 'desc'
  },
  serverSide: true,
  count: count,
  page: page,
  rowsPerPage: rowsPerPage
};

var tableData = [];

 for (var i = 0; i < dastScans.length; i++) {
   var dataItem = [];

   dataItem.push(((page) * 10) + (i+1));
   dataItem.push(dastScans[i].scanName);
   dataItem.push(dastScans[i].mainURL);
   dataItem.push(dastScans[i].application.name);
   
   dataItem.push(dastScans[i].urlsScanned);
   dataItem.push(dastScans[i].issuesCount);

   dataItem.push((new Date(dastScans[i].createdAt)).toLocaleDateString('en-US') + ' - ' + 
   (new Date(dastScans[i].createdAt)).toLocaleTimeString('en-US'));

   if (dastScans[i].scanCompletedAt) {
     dataItem.push((new Date(dastScans[i].scanCompletedAt)).toLocaleDateString('en-US')
       + ' - ' + (new Date(dastScans[i].scanCompletedAt)).toLocaleTimeString('en-US'));
   } else {
     dataItem.push('---');
   }

   dataItem.push(dastScans[i]); // for status
   dataItem.push(dastScans[i]); // for view report link
   dataItem.push(dastScans[i]._id); // for delete

   tableData.push(dataItem);
 }

 const goToStartQuickScan = (e) => {
   e.preventDefault();
   navigate('/start-dast-scan')
 }

 return (
   <div className="activeScans">
     {setModalIsOpen && (
       <Modal
         isOpen={modalIsOpen}
         onRequestClose={closeModal}
         style={customStyles}
         contentLabel="Remediations"
       >
         <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this scan?</text>
         <br/><br/>
         <button onClick={() => handleConfirmation(true)} style={{ width: 100, borderWidth: 0, backgroundColor: 'green', color:'white', padding: 10 }}>Yes</button>
         <button onClick={() => handleConfirmation(false)} style={{ marginLeft: 30, borderWidth: 0, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>No</button>
       </Modal>
     )}

     <div style={{ width: '100%' }}>
       <div>
         <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
           <h2>DAST Scans</h2>

           <p>To start a DAST scan, please download our desktop application, available for Linux, Windows and Mac OS.</p>

           <CButton
             style={{
               width: 300,
               marginBottom: '2%',
               borderWidth: 0,
               fontSize: 20,
               display:'none',
               background: '#e50202'
             }}
             onClick={goToStartQuickScan}
             color="primary"
             className="px-3"
           >
             <AiOutlineSecurityScan size={25} color='white'/>
             <span style={{marginLeft:10}}>Start a DAST Scan</span>
           </CButton>
         </div>

         {onLoading &&
           <ShimmerTable row={8} col={10} />
         }

         {!onLoading &&
           <>
             <ThemeProvider theme={getMuiTheme()}>
               <MUIDataTable
                 style={{ height: "57vh" }}
                 data={tableData}
                 columns={columns}
                 options={options}
               />
             </ThemeProvider>
           </>
         }
       </div>
     </div>
   </div>
 )
}

export default QuickScans