import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Switch, FormControlLabel, Checkbox, TextField } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoCodescan } from "react-icons/go";
import axios from 'axios';



const StartDASTScan = () => {
  const navigate = useNavigate()

  const [scanName, setScanName] = useState("")
  const [url, setURL] = useState("")
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [exclusionList, setExclusionList] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authType, setAuthType] = useState("formBased");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authUrl, setAuthUrl] = useState("");
  const [confirmRights, setConfirmRights] = useState(false);

  const toaster = useRef();

  function checkURLType(url) {
    try {
      new URL(url);
    } catch (error) {
      return false;
    }
    
    const extension = url.split('.').pop().toLowerCase();
    
    if (extension === 'json') {
      return true;
    } else if (extension === 'yaml' || extension === 'yml') {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {    
    fetchApplications();   
  }, []);

  const fetchApplications = async () => {
    setLoading(true);
    
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/applications/getAllApplications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setApplications(response.data.applications);

    if(response.data.applications.length > 0){
      setApplicationId(response.data.applications[0]._id)
    }else{
      setApplicationId('');
    }
   
    setLoading(false);
  };

  const startScan = () => {
    if (scanName === '') {
      setValidationFailed(true);
      setErrorText('Scan name is required.');
    } else if (applicationId === '') {
      setValidationFailed(true);
      setErrorText('Please select an application');
    } else if (url === '') {
      setValidationFailed(true);
      setErrorText('URL is required');
    } else if (!confirmRights) {
      setValidationFailed(true);
      setErrorText('Please confirm you have the rights to perform this scan');
    } else {
      setLoading(true)      

      const bearerToken = localStorage.getItem('ASIToken');

      const body = {
        scanName: scanName,
        applicationId: applicationId,
        url: url,
        exclusionList: exclusionList.split('\n').filter(url => url.trim() !== ''),
        isAuthenticated: isAuthenticated,
        authType: isAuthenticated ? authType : null,
        username: isAuthenticated && authType === 'formBased' ? username : null,
        password: isAuthenticated && authType === 'formBased' ? password : null,
        authUrl: isAuthenticated && authType === 'formBased' ? authUrl : null,
      }

      fetch(global.backendUrl+'/api/v1/dastScans/startDastScan', {
        method: 'POST',
        headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {
        if(data.hasOwnProperty('error')){
           setValidationFailed(true);
           setErrorText(data.error);
           setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setValidationFailed(true);
          setErrorText("Something went wrong. Please try again");
        }else{
           setSubmissionSuccess(true);
           setLoading(false);

           toast('Scan started', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
    
           navigate('/dast-scans')
        }
      })
      .catch(error => {
        console.error(error);
      });

      const timer = setTimeout(() => {
         toast('Scan started', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });

       navigate('/dast-scans')
      }, 10000);         
    }   
  }

  const goBack = (e) => {
    e.preventDefault();
    navigate('/dast-scans')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display:'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>



      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Start a DAST Scan</h2>
            <p>hello</p>

            <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  marginRight:20,
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#fff',
                  borderWidth: 1,
                  color: '#fff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight:10 }} />
                Back to Scan List
              </CButton>
          </div>
          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Scan Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Scan Name"
                autoComplete="scanName"
                className="white-input"
                onChange={(e) => setScanName(e.target.value)}
                style={{ width: '100%' }}
              />
            </CInputGroup>       
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Select application</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setApplicationId(e.target.value)}
                style={{ width: '100%' }}
              >
                {applications.map(application => (
                  <option key={application._id} value={application._id}>
                    {application.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>   
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>URL</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="URL"
                autoComplete="url"
                className="white-input"
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '100%' }}
              />
            </CInputGroup>                
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white' }}>Exclusion List</CFormLabel>
            <span style={{ color: 'white', fontSize: '15px' }}>Enter URLs to exclude from the scan, one per line.</span>

            <CInputGroup className="" style={{ flexDirection: 'column', borderRadius:10, backgroundColor:'#fff'  }}>
              <TextField
                multiline
                rows={4}
                placeholder="Enter URLs to exclude (one per line)"
                value={exclusionList}
                onChange={(e) => setExclusionList(e.target.value)}
                className="dark-input"
                style={{ width: '100%', backgroundColor:'#fff', color:'#000 !important', }}
              />
            </CInputGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isAuthenticated}
                  onChange={(e) => setIsAuthenticated(e.target.checked)}
                  color="primary"
                />
              }
              label={isAuthenticated ? "Authenticated" : "Unauthenticated"}
              style={{ marginTop: 40, color: 'white' }}
            />
            {isAuthenticated && (
              <>
                <CFormLabel htmlFor="authType" style={{ marginTop: 30, color: 'white' }}>Auth Type</CFormLabel>
                <CInputGroup className="" style={{ flexDirection: 'column' }}>
                  <CFormSelect
                    id="authType"
                    className="white-input"
                    value={authType}
                    onChange={(e) => setAuthType(e.target.value)}
                    style={{ width: '100%' }}
                  >
                    <option value="formBased">Form Based</option>
                    <option value="sso">Single Sign On</option>
                  </CFormSelect>
                </CInputGroup>
                {authType === 'formBased' && (
                  <>
                    <CFormLabel htmlFor="username" style={{ marginTop: 30, color: 'white' }}>Username</CFormLabel>
                    <CInputGroup className="" style={{ flexDirection: 'column' }}>
                      <CFormInput
                        id="username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="white-input"
                        style={{ width: '100%' }}
                      />
                    </CInputGroup>
                    <CFormLabel htmlFor="password" style={{ marginTop: 30, color: 'white' }}>Password</CFormLabel>
                    <CInputGroup className="" style={{ flexDirection: 'column' }}>
                      <CFormInput
                        id="password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="white-input"
                        style={{ width: '100%' }}
                      />
                    </CInputGroup>
                    <CFormLabel htmlFor="authUrl" style={{ marginTop: 30, color: 'white' }}>Auth URL</CFormLabel>
                    <CInputGroup className="" style={{ flexDirection: 'column' }}>
                      <CFormInput
                        id="authUrl"
                        placeholder="Auth URL"
                        value={authUrl}
                        onChange={(e) => setAuthUrl(e.target.value)}
                        className="white-input"
                        style={{ width: '100%' }}
                      />
                    </CInputGroup>
                  </>
                )}
              </>
            )}
          <br/>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmRights}
                  onChange={(e) => setConfirmRights(e.target.checked)}
                  color="primary"
                />
              }
              label="I confirm that I have the rights to perform a dynamic security scan for this application"
              style={{ marginTop: 30, color: 'white' }}
            />
            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={startScan}
              disabled={loading || !confirmRights}
            >              
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Start Scan'
              }
            </CButton>
            {submissionSuccess &&
              <span style={{color:'green', fontSize:17}}>The scan is started. 
              After the scan is complete, the scan report is sent to the email mentioned. <br/>It will also be available 
                in the dashboard under Scans menu. You may leave this window now. 
                <br/> <br/>
                Moving to scans list in 5 seconds...
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartDASTScan