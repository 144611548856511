import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BugzillaTicketingIntegration = () => {

  const [settings, setSettings] = useState({
    bugzillaTicketingEnabled: false,
    bugzillaApiKey: '',
    bugzillaApiURL: '',    
    bugzillaProduct: '',
    bugzillaComponent: ''
  });

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    const fetchOrganizationDetails = async () => {

      setIsLoading(true);

      const token = localStorage.getItem('ASIToken');

      try {
        const response = await axios.get('api/v1/organizations/organization-details/', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const orgDetails = response.data.organization;

        setSettings({
          bugzillaTicketingEnabled: orgDetails.bugzillaTicketingEnabled || false,
          bugzillaApiKey: orgDetails.bugzillaApiKey || '',
          bugzillaApiURL: orgDetails.bugzillaApiURL || '',
          bugzillaProduct: orgDetails.bugzillaProduct || '',
          bugzillaComponent: orgDetails.bugzillaComponent || ''
        });

        setIsLoading(false);
        
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching organization details:', error);
      }
    };

    fetchOrganizationDetails();

  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const token = localStorage.getItem('ASIToken');

    try {
      const response = await axios.post('/api/v1/integrations/ticketing-settings-bugzilla', settings, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Settings saved successfully:', response.data);
      // Handle success (e.g., show a success message)

      toast.success("Settings Saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } catch (error) {
      console.error('Error saving settings:', error);
      // Handle error (e.g., show an error message)
    }
  };


  if (isLoading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }
  
  return (
    <div className="sast-scan-instructions">

      <h4 className="darkText">Bugzilla Ticketing Integration</h4>
<hr/>
    <form onSubmit={handleSubmit} style={{marginTop:50}}>
      <div>
        <label style={{width:300, display:'flex', flexDirection:'row'}}>
          <input
            type="checkbox"
            name="bugzillaTicketingEnabled"
            checked={settings.bugzillaTicketingEnabled}
            onChange={handleChange}
            style={{width:20, height:20,}}
          />
          <span style={{marginLeft:10, color:'#000'}}>Enable Bugzilla Ticketing</span>
        </label>
      </div>
      
      <div style={{display:'flex', flexDirection:'column', marginTop:20}}>
        <label>Bugzilla API URL<span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          name="bugzillaApiURL"
          placeholder='Bugzilla API URL'
          value={settings.bugzillaApiURL}
          style={{color:'#000'}}
          onChange={handleChange}
          required
        />
      </div>

      <div style={{display:'flex', flexDirection:'column', marginTop:20}}>
        <label>Bugzilla API Key<span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          name="bugzillaApiKey"
          placeholder='Bugzilla API Key'
          value={settings.bugzillaApiKey}
          style={{color:'#000'}}
          onChange={handleChange}
          required
        />
      </div>
         
     
      
      
      <div style={{display:'flex', flexDirection:'column', marginTop:20}}>
        <label>Bugzilla Product<span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          name="bugzillaProduct"
          placeholder="Bugzilla Product"
          value={settings.bugzillaProduct}
          style={{color:'#000'}}
          onChange={handleChange}
          required
        />
      </div>
      
      <div style={{display:'flex', flexDirection:'column',marginTop:20}}>
        <label>Bugzilla Component<span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          name="bugzillaComponent"
          placeholder="Bugzilla Component"
          value={settings.bugzillaComponent}
          style={{color:'#000'}}
          onChange={handleChange}
          required
        />
      </div>
      
      <button className="primaryButton" type="submit" style={{marginTop:20, width:500, background:'#e50202', borderColor:'#e50202', 
    borderWidth:0, color:'#fff', padding:10, borderRadius:10 }}>
        Save Settings</button>
    </form>

      
    </div>
  );
};

export default BugzillaTicketingIntegration;