import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

const DefaultLayout = () => {  
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      const user = localStorage.getItem('ASIUser');
      if (!user) {
        navigate('/');
      } else {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, [navigate]);

  if (isLoading) {
    return null; // or return a loading spinner if you prefer
  }

  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100">
        <AppHeader/>
        <div className="body flex-grow-1 px-3" style={{display:'flex', flexDirection:'row'}}>
          <AppSidebar/>
          <AppContent/>
        </div>       
      </div>
    </div>
  )
}

export default DefaultLayout