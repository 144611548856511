import React, { useState, useRef } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CFormInput,
  CInputGroup,
  CFormLabel,
} from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateEntityName } from '../../../../helpers/validation';

const AddAssetGroup = () => {
  const navigate = useNavigate();

  const [assetGroupName, setAssetGroupName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const toaster = useRef();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const validateForm = () => {
    if (!validateEntityName(assetGroupName)) {
      setError('Invalid asset group name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).');
      return false;
    }
    setError('');
    return true;
  };

  const addAssetGroup = () => {
    if (validateForm()) {
      setLoading(true);

      const bearerToken = localStorage.getItem('ASIToken');

      const requestData = {
        name: assetGroupName,
      };

      fetch(global.backendUrl + '/api/v1/organizations/asset-groups', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.hasOwnProperty('error')) {
            setError(data.error);
            setLoading(false);
          }
          else if (data.hasOwnProperty('err')) {
            setLoading(false);
            setError("Something went wrong. Please try again");
          } else {
            setSubmissionSuccess(true);
            setLoading(false);

            toast('Asset group added', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            navigate('/asset-groups');
          }
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
          setError("An error occurred. Please try again.");
        });
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/asset-groups');
  };

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Add Asset Group</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Asset Groups
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Asset Group Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Asset Group Name"
                autoComplete="assetGroupName"
                className="white-input"
                onChange={(e) => setAssetGroupName(e.target.value)}
                style={{ width: '100%' }}
                maxLength={100}
              />
              {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
            </CInputGroup>

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={addAssetGroup}
              disabled={loading}
            >
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save Asset Group'
              }
            </CButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddAssetGroup;