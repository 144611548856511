import React from 'react';

const VisualStudioIntegration = () => {
  return (
    <div className="visual-studio-integration-guide">
      <h2 className="darkText">Visual Studio Integration for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Prerequisites</h3>
        <ul>
          <li>Ensure Python 3.12 or later is installed on your system.</li>
          <li>Install the 'requests' library using pip: <code>pip install requests</code></li>
          <li>Create the SAST and SCA check scripts (sast_check.py and sca_check.py) on your system.</li>
          <li>Your project should have an 'intrucept-config.txt' file in its root directory with your personal credentials.</li>
        </ul>
      </section>

      <section>
        <h3 className="darkText">Step 1: Set Up intrucept-config.txt</h3>
        <ol>
          <li>In your project's root directory, create a file named <code>intrucept-config.txt</code>.</li>
          <li>Add the following content to the file, replacing the values with your actual Intrucept credentials:
            <pre>
              <code>
{`CLIENT_ID = your_intrucept_client_id
CLIENT_SECRET = your_intrucept_client_secret
APPLICATION_ID = your_intrucept_application_id`}
              </code>
            </pre>
          </li>
          <li>Save the file.</li>
        </ol>
        <p><strong>Note:</strong> Keep this file secure and do not commit it to version control.</p>
      </section>

      <section>
        <h3 className="darkText">Step 2: Create SAST and SCA Check Scripts</h3>
        <p>Create two Python scripts with the following content:</p>

        <h4>sast_check.py</h4>
        <pre>
          <code>
{`import os
import sys
import zipfile
import requests
from datetime import datetime
import subprocess
import traceback
import json

def write_output(message, file):
    print(message)  # Still print to console
    try:
        file.write(message + '\\n')
        file.flush()  # Ensure it's written immediately
    except UnicodeEncodeError:
        file.write(message.encode('utf-8', errors='ignore').decode('utf-8') + '\\n')
        file.flush()

def zip_project(project_path, output_zip, output_file):
    try:
        with zipfile.ZipFile(output_zip, 'w', zipfile.ZIP_DEFLATED) as zipf:
            for root, _, files in os.walk(project_path):
                for file in files:
                    file_path = os.path.join(root, file)
                    arcname = os.path.relpath(file_path, project_path)
                    if not (file.startswith('.') or 'node_modules' in file_path or 'vendor' in file_path):
                        zipf.write(file_path, arcname)
        write_output(f"Project zipped to {output_zip}", output_file)
    except Exception as e:
        write_output(f"Error zipping project: {str(e)}", output_file)
        write_output(traceback.format_exc(), output_file)
        raise

def perform_sast_scan(zip_file, output_file):
    url = "https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans"
    
    headers = {
        "Client-ID": "your_actual_client_id",
        "Client-Secret": "your_actual_client_secret"
    }
    
    data = {
        "applicationId": "your_actual_application_id",
        "scanName": f"SAST Scan - {datetime.now().strftime('%Y%m%d%H%M%S')}",
        "language": "csharp"  # Adjust based on your project
    }
    
    try:
        write_output(f"Sending request to {url}", output_file)
        write_output(f"Headers: {headers}", output_file)
        write_output(f"Data: {data}", output_file)
        
        with open(zip_file, 'rb') as f:
            files = {'projectZipFile': f}
            response = requests.post(url, headers=headers, data=data, files=files)
        
        write_output(f"Response status code: {response.status_code}", output_file)
        write_output("Response content:", output_file)
        write_output(json.dumps(response.json(), indent=2, ensure_ascii=False), output_file)
        
        response.raise_for_status()  # Raise an exception for bad status codes
        return response.json()
    except requests.exceptions.RequestException as e:
        write_output(f"Error in API call: {str(e)}", output_file)
        write_output(traceback.format_exc(), output_file)
        raise

def main():
    if len(sys.argv) < 2:
        print("Usage: python sast_check.py <project_path>")
        sys.exit(1)

    project_path = sys.argv[1]
    zip_file = os.path.join(os.path.dirname(project_path), "project.zip")
    output_file_path = os.path.join(project_path, "sast_check_results.txt")

    with open(output_file_path, 'w', encoding='utf-8') as output_file:
        try:
            write_output(f"Starting SAST check for project: {project_path}", output_file)
            write_output(f"Python version: {sys.version}", output_file)
            write_output(f"Requests library version: {requests.__version__}", output_file)
            
            write_output("Zipping project...", output_file)
            zip_project(project_path, zip_file, output_file)

            write_output("Performing SAST scan...", output_file)
            response = perform_sast_scan(zip_file, output_file)

            write_output("\\nSAST Scan Results:", output_file)
            write_output(f"Can Proceed: {response.get('canProceed', False)}", output_file)
            write_output("\\nVulnerabilities Table:", output_file)
            write_output(response.get('vulnsTable', 'No vulnerabilities table provided'), output_file)

        except Exception as e:
            write_output(f"An error occurred: {str(e)}", output_file)
            write_output(traceback.format_exc(), output_file)
        
        finally:
            # Clean up
            if os.path.exists(zip_file):
                os.remove(zip_file)
            write_output("SAST check completed.", output_file)
            write_output(f"Results saved to: {output_file_path}", output_file)

    # Open the results file
    if sys.platform.startswith('win'):
        os.startfile(output_file_path)
    elif sys.platform.startswith('darwin'):
        subprocess.call(('open', output_file_path))
    else:
        subprocess.call(('xdg-open', output_file_path))

if __name__ == "__main__":
    main()`}
          </code>
        </pre>

        <h4>sca_check.py</h4>
        <pre>
          <code>
{`import os
import sys
import zipfile
import requests
from datetime import datetime
import subprocess
import traceback
import json

def write_output(message, file):
    print(message)  # Still print to console
    try:
        file.write(message + '\\n')
        file.flush()  # Ensure it's written immediately
    except UnicodeEncodeError:
        file.write(message.encode('utf-8', errors='ignore').decode('utf-8') + '\\n')
        file.flush()

def zip_project(project_path, output_zip, output_file):
    try:
        with zipfile.ZipFile(output_zip, 'w', zipfile.ZIP_DEFLATED) as zipf:
            for root, _, files in os.walk(project_path):
                for file in files:
                    file_path = os.path.join(root, file)
                    arcname = os.path.relpath(file_path, project_path)
                    if not (file.startswith('.') or 'node_modules' in file_path or 'vendor' in file_path):
                        zipf.write(file_path, arcname)
        write_output(f"Project zipped to {output_zip}", output_file)
    except Exception as e:
        write_output(f"Error zipping project: {str(e)}", output_file)
        write_output(traceback.format_exc(), output_file)
        raise

def perform_sca_scan(zip_file, output_file):
    url = "https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans"
    
    headers = {
        "Client-ID": "your_actual_client_id",
        "Client-Secret": "your_actual_client_secret"
    }
    
    data = {
        "applicationId": "your_actual_application_id",
        "scanName": f"SCA Scan - {datetime.now().strftime('%Y%m%d%H%M%S')}",
        "language": "csharp"  # Adjust based on your project
    }
    
    try:
        write_output(f"Sending request to {url}", output_file)
        write_output(f"Headers: {headers}", output_file)
        write_output(f"Data: {data}", output_file)
        
        with open(zip_file, 'rb') as f:
            files = {'projectZipFile': f}
            response = requests.post(url, headers=headers, data=data, files=files)
        
        write_output(f"Response status code: {response.status_code}", output_file)
        write_output("Response content:", output_file)
        write_output(json.dumps(response.json(), indent=2, ensure_ascii=False), output_file)
        
        response.raise_for_status()  # Raise an exception for bad status codes
        return response.json()
    except requests.exceptions.RequestException as e:
        write_output(f"Error in API call: {str(e)}", output_file)
        write_output(traceback.format_exc(), output_file)
        raise

def main():
    if len(sys.argv) < 2:
        print("Usage: python sca_check.py <project_path>")
        sys.exit(1)

    project_path = sys.argv[1]
    zip_file = os.path.join(os.path.dirname(project_path), "project.zip")
    output_file_path = os.path.join(project_path, "sca_check_results.txt")

    with open(output_file_path, 'w', encoding='utf-8') as output_file:
        try:
            write_output(f"Starting SCA check for project: {project_path}", output_file)
            write_output(f"Python version: {sys.version}", output_file)
            write_output(f"Requests library version: {requests.__version__}", output_file)
            
            write_output("Zipping project...", output_file)
            zip_project(project_path, zip_file, output_file)

            write_output("Performing SCA scan...", output_file)
            response = perform_sca_scan(zip_file, output_file)

            write_output("\\nSCA Scan Results:", output_file)
            write_output(f"Can Proceed: {response.get('canProceed', False)}", output_file)
            write_output("\\nDependencies Report:", output_file)
            write_output(response.get('dependenciesReport', 'No dependencies report provided'), output_file)

        except Exception as e:
            write_output(f"An error occurred: {str(e)}", output_file)
            write_output(traceback.format_exc(), output_file)
        
        finally:
            # Clean up
            if os.path.exists(zip_file):
                os.remove(zip_file)
            write_output("SCA check completed.", output_file)
            write_output(f"Results saved to: {output_file_path}", output_file)

    # Open the results file
    if sys.platform.startswith('win'):
        os.startfile(output_file_path)
    elif sys.platform.startswith('darwin'):
        subprocess.call(('open', output_file_path))
    else:
        subprocess.call(('xdg-open', output_file_path))

if __name__ == "__main__":
    main()`}
          </code>
        </pre>

        <p><strong>Important:</strong> In both scripts, replace "your_actual_client_id", "your_actual_client_secret", and "your_actual_application_id" with the values from your intrucept-config.txt file.</p>
      </section>

      <section>
        <h3 className="darkText">Step 3: Configure External Tools in Visual Studio</h3>
        <ol>
          <li>Open Visual Studio.</li>
          <li>Go to <strong>Tools</strong> {'>'} <strong>External Tools</strong>.</li>
          <li>In the External Tools dialog, click <strong>Add</strong> to create a new tool for SAST Check.</li>
          <li>Configure the SAST Check tool as follows:
            <ul>
              <li><strong>Title:</strong> SAST Check</li>
              <li><strong>Command:</strong> C:\Python312\python.exe (adjust this path if necessary)</li>
              <li><strong>Arguments:</strong> "path\to\your\sast_check.py" $(ProjectDir)</li>
              <li><strong>Initial directory:</strong> $(ProjectDir)</li>
            </ul>
          </li>
          <li>Click <strong>Apply</strong>, then <strong>Add</strong> to create a new tool for SCA Check.</li>
          <li>Configure the SCA Check tool as follows:
            <ul>
              <li><strong>Title:</strong> SCA Check</li>
              <li><strong>Command:</strong> C:\Python312\python.exe (adjust this path if necessary)</li>
              <li><strong>Arguments:</strong> "path\to\your\sca_check.py" $(ProjectDir)</li>
              <li><strong>Initial directory:</strong> $(ProjectDir)</li>
            </ul>
          </li>
          <li>Click <strong>OK</strong> to save both tool configurations.</li>
        </ol>
        <p><strong>Note:</strong> Make sure to replace "path\to\your" with the actual path where you saved the Python scripts.</p>
      </section>

      <section>
        <h3 className="darkText">Step 4: Running the Scans</h3>
        <ol>
          <li>Open your project in Visual Studio.</li>
          <li>To run a scan, go to <strong>Tools</strong> {'>'} <strong>SAST Check</strong> or <strong>SCA Check</strong>.</li>
          <li>The scan will start, and the output will be displayed in a new window.</li>
          <li>Once the scan is complete, the results file will automatically open.</li>
        </ol>
      </section>

    
    </div>
  );
};

export default VisualStudioIntegration;