import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Paper,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Divider,
  Chip,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ArrowBack } from '@mui/icons-material';


const ViewOrganization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      navigate('/organizations');
      return;
    }
    fetchOrganizationDetails();
  }, [id]);

  const fetchOrganizationDetails = async () => {
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get(`/api/v1/superadmin/organizations/${id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      setOrganization(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch organization details');
      navigate('/organizations');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!organization) {
    return null;
  }

  return (
    <Box sx={{ p: 3 }}>
       

      <Paper sx={{ p: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/edit-organization?id=${id}`)}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              onClick={() => navigate('/organizations')}
            >
              Back
            </Button>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />

        <Grid container spacing={3}>
          {/* Basic Information */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Basic Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Organization Name</Typography>
                    <Typography variant="body1" gutterBottom>{organization.name}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Client ID</Typography>
                    <Typography variant="body1" gutterBottom>{organization.clientId}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="textSecondary">Created On</Typography>
                    <Typography variant="body1" gutterBottom>
                      {new Date(organization.createdAt).toLocaleDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Integration Status */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Ticketing Integration Status</Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 2 }}>
                  <Chip
                    label="Azure DevOps"
                    color={organization.azureDevOpsTicketingEnabled ? "success" : "default"}
                    variant={organization.azureDevOpsTicketingEnabled ? "filled" : "outlined"}
                  />
                  <Chip
                    label="Bugzilla"
                    color={organization.bugzillaTicketingEnabled ? "success" : "default"}
                    variant={organization.bugzillaTicketingEnabled ? "filled" : "outlined"}
                  />
                  <Chip
                    label="GitHub"
                    color={organization.githubTicketingEnabled ? "success" : "default"}
                    variant={organization.githubTicketingEnabled ? "filled" : "outlined"}
                  />
                  <Chip
                    label="Jira"
                    color={organization.jiraTicketingEnabled ? "success" : "default"}
                    variant={organization.jiraTicketingEnabled ? "filled" : "outlined"}
                  />
                  <Chip
                    label="Intruct"
                    color={organization.intruceptTicketingEnabled ? "success" : "default"}
                    variant={organization.intruceptTicketingEnabled ? "filled" : "outlined"}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Integration Details */}
          {organization.azureDevOpsTicketingEnabled && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Azure DevOps Configuration</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Organization URL</Typography>
                      <Typography variant="body1" gutterBottom>{organization.azureDevopsOrgUrl}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Project Name</Typography>
                      <Typography variant="body1" gutterBottom>{organization.azureDevopsProjectName}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {organization.bugzillaTicketingEnabled && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Bugzilla Configuration</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">API URL</Typography>
                      <Typography variant="body1" gutterBottom>{organization.bugzillaApiURL}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Product</Typography>
                      <Typography variant="body1" gutterBottom>{organization.bugzillaProduct}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Component</Typography>
                      <Typography variant="body1" gutterBottom>{organization.bugzillaComponent}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {organization.githubTicketingEnabled && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>GitHub Configuration</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Username</Typography>
                      <Typography variant="body1" gutterBottom>{organization.githubUsername}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Repository Name</Typography>
                      <Typography variant="body1" gutterBottom>{organization.gitHubRepositoryName}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {organization.jiraTicketingEnabled && (
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>Jira Configuration</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Jira URL</Typography>
                      <Typography variant="body1" gutterBottom>{organization.jiraUrl}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Email</Typography>
                      <Typography variant="body1" gutterBottom>{organization.jiraEmail}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Project Key</Typography>
                      <Typography variant="body1" gutterBottom>{organization.jiraProjectKey}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ViewOrganization;