import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import { Link } from "react-router-dom";

import '../style.css'; 

import {  Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";

const Counters = () => {


  const [dashboardData, setDashboardData] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {
    setOnLoading(true);
  
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/dashboard', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setDashboardData(response.data.data);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setOnLoading(false);
    }
  };

  return (
    <>    


      <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', backgroundColor: '#fff',
            borderRadius: 10, padding: 20,
          }}>

            <Link to="/users"
              style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

              <FaUsers size={40} style={{ color: '#ffaa05' }} />

            </Link>

            <Link to="/users" style={{ textDecoration: 'none' }}>

              <div className="dashboardLinkDiv">

                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.usersCount}</h3>
                }

                <span style={{ fontSize: 16, color: '#ffaa05', }}>Users</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/asset-groups" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <FaObjectGroup size={40} style={{ color: '#7366ff' }} />
            </Link>

            <Link to="/asset-groups" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.assetGroupsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#7366ff', }}>Asset Groups</span>
              </div>
            </Link>

          </div>

          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, }}>

            <Link to="/business-units" style={{
              textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center',
              justifyContent: 'center'
            }}>
              <IoIosBusiness size={40} style={{ color: '#1ecbe1' }} />
            </Link>

            <Link to="/asset-groups" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.businessUnitsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#7366ff', }}>Business Units</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20,
          }}>

            <Link to="/applications" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdApps size={40} style={{ color: '#f73164', }} />
            </Link>

            <Link to="/applications" style={{ textDecoration: 'none', width: '50%', }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.applicationsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Applications</span>
              </div>
            </Link>

          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/scans" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AiOutlineSecurityScan size={40} style={{ color: '#ffaa05' }} />
            </Link>

            <Link to="/scans" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.scansCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#ffaa05', }}>Scans</span>
              </div>
            </Link>
          </div>

          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MdErrorOutline size={40} style={{ color: '#f73164' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.issuesCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Issues</span>
              </div>
            </Link>
          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SiHelpdesk size={40} style={{ color: '#a12dd2' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.openTicketsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Open Tickets</span>
              </div>
            </Link>
          </div>


          <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'row', borderRadius: 10, padding: 20, marginTop: 30 }}>

            <Link to="/tickets" style={{ textDecoration: 'none', background: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SiHelpdesk size={40} style={{ color: 'green' }} />
            </Link>

            <Link to="/tickets" style={{ textDecoration: 'none' }}>
              <div className="dashboardLinkDiv">
                {onLoading ?
                  <div style={{ display: 'flex', flexDirection: 'column', height: 35, justifyContent: 'space-between', marginBottom: 5 }}>
                    <Breathing width={100} height={5} />
                    <Breathing width={70} height={5} style={{ marginTop: 3 }} />
                    <Breathing width={50} height={5} style={{ marginTop: 3 }} />
                  </div>
                  :
                  <h3 style={{ color: '#fff', fontWeight: 600, }}>{dashboardData.closedTicketsCount}</h3>
                }
                <span style={{ fontSize: 16, color: '#f73164', }}>Resolved Tickets</span>
              </div>
            </Link>
          </div>

    </>
  )
}

export default Counters