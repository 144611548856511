import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import '../style.css'; 

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

const TopOpenApplicationFindings = () => {


  const [findings, setFindings] = useState({})
  const [onLoading, setOnLoading] = useState(false)
  
  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);
  
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/organization-level-top-open-applications-findings', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setFindings(response.data);
    } catch (error) {
      console.error("Error fetching top open application findings:", error);
    } finally {
      setOnLoading(false);
    }
  };
 


  return (
    <>    


<div className="dashboardCard" style={{
            display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
            borderRadius: 10, padding: 20, width: '47%'
          }}>


            <span style={{ fontSize: 15 }}>TOP OPEN APPLICATION FINDINGS</span>
            <hr style={{ borderColor: '#fff' }} />


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiBoxList size={50} style={{ color: 'rgb(115, 102, 255)' }} />

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>{findings.top25CWE}</div>
                  <span style={{ fontSize: 13 }}>Top 25 CWE</span>
                </div>


              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <CiViewList size={50} style={{ color: '#FFA500' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: '#FFA500', fontSize: 25 }}>{findings.owaspTop10}</div>
                  <span style={{ fontSize: 13 }}>OWASP Top 10</span>
                </div>


              </div >

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                <FaListUl size={50} style={{ color: 'rgb(247, 49, 100)' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
                  <div style={{ color: 'rgb(247, 49, 100)', fontSize: 25 }}>{findings.top10PCIDSS}</div>
                  <span style={{ fontSize: 13 }}>Top 10 PCI DSS</span>
                </div>


              </div>
            </div>
          </div>

    </>
  )
}

export default TopOpenApplicationFindings