import React from 'react'

import Organizations from './views/app-pages/superadmin/organizations';
import AddOrganization from './views/app-pages/superadmin/addOrganization';
import EditOrganization from './views/app-pages/superadmin/editOrganization';
import ViewOrganization from './views/app-pages/superadmin/viewOrganization';

import UserDashboard from './views/app-pages/UserDashboard';
import SASTScans from './views/app-pages/sast/allScans';
import SASTScanDetail from './views/app-pages/sast/scanDetails';

import SCAScans from './views/app-pages/sca/allScans';
import SCAScanDetail from './views/app-pages/sca/scanDetails';

import DASTScans from './views/app-pages/dast/allScans';
import DASTScanDetail from './views/app-pages/dast/scanDetails';

import Applications from './views/app-pages/organization/applications/applications';
import Application from './views/app-pages/organization/applications/application';
import AddApplication from './views/app-pages/organization/applications/addApplication';
import EditApplication from './views/app-pages/organization/applications/editApplication';

import StartSASTScan from './views/app-pages/sast/startSASTScan';
import StartSCAScan from './views/app-pages/sca/startSCAScan';
import StartDASTScan from './views/app-pages/dast/startDASTScan';

import Integrations from './views/app-pages/integrations/integrations';
import Organization from './views/app-pages/organization/organization';
import Tickets from './views/app-pages/organization/ticketing/tickets';
import OpenTicket from './views/app-pages/organization/ticketing/openTicket';
import Ticket from './views/app-pages/organization/ticketing/ticket';
import EditTicket from './views/app-pages/organization/ticketing/editTicket';

import Users from './views/app-pages/organization/users/users';
import AddUser from './views/app-pages/organization/users/addUser';
import EditUser from './views/app-pages/organization/users/editUser';

import AssetGroups from './views/app-pages/organization/assetGroups/assetGroups';
import AddAssetGroup from './views/app-pages/organization/assetGroups/addAssetGroup';
import EditAssetGroup from './views/app-pages/organization/assetGroups/editAssetGroup';
import BusinessUnits from './views/app-pages/organization/businessUnits/businessUnits';
import AddBusinessUnit from './views/app-pages/organization/businessUnits/addBusinessUnit';
import EditBusinessUnit from './views/app-pages/organization/businessUnits/editBusinessUnit';

const routes = [

  { path: '/organizations', name: 'Superadmin - Organizations', element: Organizations},
  { path: '/add-organization', name: 'Add Organization', element: AddOrganization},
  { path: '/edit-organization', name: 'Edit Organization', element: EditOrganization},
  { path: '/view-organization', name: 'View Organization', element: ViewOrganization},

  { path: '/dashboard', name: 'User Dashboard', element: UserDashboard},

  { path: '/sast-scans', name: 'SAST Scans', element: SASTScans},  
  { path: '/sast-scan-detail', name: 'SAST Scan Detail', element: SASTScanDetail}, 
  { path: '/start-sast-scan', name: 'Start SAST Scan', element: StartSASTScan},   

  { path: '/sca-scans', name: 'SCA Scans', element: SCAScans},  
  { path: '/sca-scan-detail', name: 'SCA Scan Detail', element: SCAScanDetail}, 
  { path: '/start-sca-scan', name: 'Start SCA Scan', element: StartSCAScan},  

  { path: '/dast-scans', name: 'DAST Scans', element: DASTScans},  
  { path: '/dast-scan-detail', name: 'DAST Scan Detail', element: DASTScanDetail}, 
  { path: '/start-dast-scan', name: 'Start DAST Scan', element: StartDASTScan},  

  { path: '/applications', name: 'Applications', element: Applications},  
  { path: '/application', name: 'Application', element: Application},  
  { path: '/add-application', name: 'Add Application', element: AddApplication},  
  { path: '/edit-application', name: 'Edit Application', element: EditApplication},  

  { path: '/asset-groups', name: 'Asset Groups', element: AssetGroups},  
  { path: '/add-asset-group', name: 'Add Asset Group', element: AddAssetGroup},  
  { path: '/edit-asset-group', name: 'Edit Asset Group', element: EditAssetGroup},  

  { path: '/business-units', name: 'Business Units', element: BusinessUnits},  
  { path: '/add-business-unit', name: 'Add Business Unit', element: AddBusinessUnit},  
  { path: '/edit-business-unit', name: 'Edit Business Unit', element: EditBusinessUnit},  

  { path: '/users', name: 'Users', element: Users},  
  { path: '/add-user', name: 'Add User', element: AddUser},  
  { path: '/edit-user', name: 'Edit User', element: EditUser},  

  { path: '/integrations', name: 'Integrations', element: Integrations},  
  { path: '/organization', name: 'Organization', element: Organization},  
  { path: '/tickets', name: 'Tickets', element: Tickets}, 
  { path: '/open-ticket', name: 'Open Ticket', element: OpenTicket},  
  { path: '/edit-ticket', name: 'Edit Ticket', element: EditTicket},  
  { path: '/ticket', name: 'Ticket', element: Ticket}, 

]

export default routes 