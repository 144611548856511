import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/intrucept_logo.svg'
import { useParams, useNavigate } from 'react-router-dom'


import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

const PasswordReset = () => {
  const navigate = useNavigate();
  const {role} = useParams();
  const showRole = () => {alert(role)}
  return (
    <div className="bg-secondary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center ">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4 border  border-end-0 bg-light">
                <CCardBody>
                  <CForm>
                    <h3 style={{  marginTop: '3rem' }}> Password Recovery</h3>
                    <CInputGroup className="mb-3 mt-3">
                      <CFormInput placeholder="Enter New Password" autoComplete="username" />
                    </CInputGroup>
                    <CInputGroup className="mb-4 ">
                      <CFormInput
                        type="password"
                        placeholder="Confirm Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <Link to={"/login/:role"}> 
                        <CButton color="primary" className="px-3 " style={{ width: '23rem', background:"#5F259E"  }} onClick={()=>alert("password changed")} >
                          Reset Password
                        </CButton>
                        </Link>
                      
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white border-3 border-start-0 " style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <img src={logo} style={{ width: '60%' }} />                    
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default PasswordReset
