import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from '@mui/material';

import {
  CButton,  
  CFormInput,
  CInputGroup,
  CFormLabel,
  CFormSelect,
} from '@coreui/react'
import {  useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { validateEntityName, validateURL, validateHost } from '../../../../helpers/validation';

const AddApplication = () => {

  const navigate = useNavigate()

  const [applicationName, setApplicationName] = useState("")
  const [loading, setLoading] = useState(false)  

  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])

  const [applicationType, setApplicationType] = useState('')
  const [assetGroup, setAssetGroup] = useState('')
  const [businessUnit, setBusinessUnit] = useState('')
  const [businessOwner, setBusinessOwner] = useState('')
  const [businessImpact, setBusinessImpact] = useState('')
  const [testingStatus, setTestingStatus] = useState('YET TO START')
  const [url, setURL] = useState('')
  const [host, setHost] = useState('')
  const [description, setDescription] = useState('')

  const [errors, setErrors] = useState({
    applicationName: '',
    applicationType: '',
    businessUnit: '',
    assetGroup: '',
    businessOwner: '',
    url: '',
    host: '',
    description: '',
    general: '',
  });


  const appTypes = ['Website', 'Desktop Application', 'Mobile Application', 'Web Service', 'Others']
  const businessImpacts = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL']
  const testingStatuses = ['YET TO START', 'IN PROGRESS', 'COMPLETED', 'UNDER REVIEW']

  useEffect(() => {
    fetchUsers();
    fetchBusinessUnits();
    fetchAssetGroups();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/organizations/users/all/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
      
      setUsers(response.data.users);
      setBusinessOwner('');
    } catch (error) {
      console.error('Error fetching organization users:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBusinessUnits = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/organizations/business-units/all/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBusinessUnits(response.data.businessUnits);
      setBusinessUnit('');
    } catch (error) {
      console.error('Error fetching business units:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssetGroups = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/organizations/asset-groups/all/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAssetGroups(response.data.assetGroups);
      setAssetGroup('');
    } catch (error) {
      console.error('Error fetching asset groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      applicationName: '',
      applicationType: '',
      businessUnit: '',
      assetGroup: '',
      businessOwner: '',
      url: '',
      host: '',
      description: '',
      general: '',
    };

    if (!applicationName) {
      newErrors.applicationName = 'Application name is mandatory.';
      isValid = false;
    } else if (!validateEntityName(applicationName)) {
      newErrors.applicationName = 'Invalid application name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).';
      isValid = false;
    }

    if (!applicationType) {
      newErrors.applicationType = 'Application type is mandatory.';
      isValid = false;
    }

    if (!businessUnit) {
      newErrors.businessUnit = 'Business unit is mandatory.';
      isValid = false;
    }

    if (!assetGroup) {
      newErrors.assetGroup = 'Asset group is mandatory.';
      isValid = false;
    }

    if (!businessOwner) {
      newErrors.businessOwner = 'Business owner is mandatory.';
      isValid = false;
    }

    if (url && !validateURL(url)) {
      newErrors.url = 'Invalid URL format.';
      isValid = false;
    }

    if (host && !validateHost(host)) {
      newErrors.host = 'Invalid host. Please enter a valid URL, hostname, or IP address.';
      isValid = false;
    }

    if (description.length > 10000) {
      newErrors.description = 'Description must not exceed 10000 characters.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const addApplication = async () => {
    if (validateForm()) {
      setLoading(true);
      const bearerToken = localStorage.getItem('ASIToken');
  
      const requestData = {
        name: applicationName,
        type: applicationType,
        businessImpact: businessImpact,
        businessUnit: businessUnit,
        assetGroup: assetGroup,
        testingStatus: testingStatus,
        description: description,
        url: url,
        host: host,
        businessOwner: businessOwner,
      };
  
      try {
        const response = await axios.post('/api/v1/applications', requestData, {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        const data = response.data;
  
        if (data.hasOwnProperty('error')) {
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
        } else if (data.hasOwnProperty('err')) {
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          toast('Application added', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate('/applications');
        }
      } catch (error) {
        console.error(error);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      } finally {
        setLoading(false);
      }
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/applications')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Add Application</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Applications List
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Application Name"
                autoComplete="applicationName"
                className="white-input"
                onChange={(e) => setApplicationName(e.target.value)}
                style={{ width: '100%' }}
                maxLength={100}
              />
              {errors.applicationName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.applicationName}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Type</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="applicationType"
                className="white-input"
                onChange={(e) => setApplicationType(e.target.value)}
                value={applicationType}
                style={{ width: '100%' }}
              >
                <option value="" key="" disabled>
                  Please select
                </option>
                {appTypes.map(appType => (
                  <option key={appType} value={appType}>
                    {appType}
                  </option>
                ))}
              </CFormSelect>
              {errors.applicationType && <div style={{ color: 'red', marginTop: '5px' }}>{errors.applicationType}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                value={businessUnit}
                style={{ width: '100%' }}
              >
                <option value="" key="" disabled>
                  Please select
                </option>
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
              {errors.businessUnit && <div style={{ color: 'red', marginTop: '5px' }}>{errors.businessUnit}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Asset Group</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="assetGroup"
                className="white-input"
                onChange={(e) => setAssetGroup(e.target.value)}
                value={assetGroup}
                style={{ width: '100%' }}
              >
                <option value="" key="" disabled>
                  Please select
                </option>
                {assetGroups.map(assetGroup => (
                  <option key={assetGroup._id} value={assetGroup._id}>
                    {assetGroup.name}
                  </option>
                ))}
              </CFormSelect>
              {errors.assetGroup && <div style={{ color: 'red', marginTop: '5px' }}>{errors.assetGroup}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Owner</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessOwner"
                className="white-input"
                onChange={(e) => setBusinessOwner(e.target.value)}
                value={businessOwner}
                style={{ width: '100%' }}
              >
                <option value="" key="" disabled>
                  Please select
                </option>
                {users
                  .filter(user => user.status !== 'Suspended')
                  .map(user => (
                    <option key={user._id} value={user._id}>
                      {user.firstName} {user.lastName} ({user.email})
                    </option>
                  ))}
              </CFormSelect>
              {errors.businessOwner && <div style={{ color: 'red', marginTop: '5px' }}>{errors.businessOwner}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Impact</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessImpact"
                className="white-input"
                onChange={(e) => setBusinessImpact(e.target.value)}
                value={businessImpact}
                style={{ width: '100%' }}
              >
                <option value="" key="" disabled>
                  Please select
                </option>
                {businessImpacts.map(businessImpact => (
                  <option key={businessImpact} value={businessImpact}>
                    {businessImpact}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Testing Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="testingStatus"
                className="white-input"
                onChange={(e) => setTestingStatus(e.target.value)}
                value={testingStatus}
                style={{ width: '100%' }}
              >
                
                {testingStatuses.map(testingStatus => (
                  <option key={testingStatus} value={testingStatus}>
                    {testingStatus}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>URL</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="URL"
                autoComplete="url"
                className="white-input"
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.url && <div style={{ color: 'red', marginTop: '5px' }}>{errors.url}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Host</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Host"
                autoComplete="host"
                className="white-input"
                onChange={(e) => setHost(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.host && <div style={{ color: 'red', marginTop: '5px' }}>{errors.host}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Description</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <textarea
                id="formTextarea"
                placeholder="Description"
                autoComplete="description"
                className="form-control white-input"
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                maxLength={10000}
              />
              <div style={{ color: 'white', marginTop: '5px' }}>{description.length}/10000</div>
              {errors.description && <div style={{ color: 'red', marginTop: '5px' }}>{errors.description}</div>}
            </CInputGroup>

            {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={addApplication}
              disabled={loading}
            >
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save Application'
              }
            </CButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default AddApplication