import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from '@mui/material';

import {
  CButton,  
  CFormInput,
  CInputGroup,
  CFormLabel,
  CFormSelect
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { validateEntityName } from '../../../helpers/validation';

const StartSASTScan = () => {
  const navigate = useNavigate()

  const [scanName, setScanName] = useState("")
  const [email, setEmail] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")  
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])

  const [progressMessage, setProgressMessage] = useState('')

  const [errors, setErrors] = useState({
    scanName: '',
    applicationId: '',
    file: '',
    general: '',
  });

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  

  function isExceedingFileSizeLimit(file) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 500) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {    
    fetchApplications();   
  }, []);

  const fetchApplications = async () => {
    setLoading(true);
    setProgressMessage('Setting up...')
    
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/applications`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setApplications(response.data.applications);

    if(response.data.applications.length > 0){
      setApplicationId(response.data.applications[0]._id)
    }else{
      setApplicationId('');
    }
   
    setLoading(false);
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      scanName: '',
      applicationId: '',
      file: '',
      general: '',
    };

    if (!validateEntityName(scanName)) {
      newErrors.scanName = 'Invalid scan name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).';
      isValid = false;
    }

    if (applicationId === '') {
      newErrors.applicationId = 'Please select an application';
      isValid = false;
    }

    if (file === null) {
      newErrors.file = 'Please select a project zip file';
      isValid = false;
    } else if (isExceedingFileSizeLimit(file)) {
      newErrors.file = 'The file size cannot be more than 500MB. Please reach out to customer support.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const startScan = () => {

    if (validateForm()) {
      setLoading(true)      
      setProgressMessage('Starting Static Application Security Testing...')

      const bearerToken = localStorage.getItem('ASIToken');

      // Create a FormData object
      const formData = new FormData();
      formData.append('scanName', scanName);
      formData.append('projectZipFile', file);
      formData.append('applicationId', applicationId);

      console.log('scanName:', scanName)
      console.log('file:', file)
      console.log('applicationId:', applicationId)

      // Make the API call
      fetch(global.backendUrl+'/api/v1/sast-scans', {
        method: 'POST',
        headers: {
        'Authorization': `Bearer ${bearerToken}`
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.hasOwnProperty('error')){
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
          setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          setSubmissionSuccess(true);
          setLoading(false);
          toast('Scan started', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate('/sast-scans')
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      });
    }   
  }
 
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/sast-scans')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection:'column',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
        <span style={{color:'#fff', marginTop:15}}>{progressMessage}</span>
      </div>
    );
  }

  return (
    <div style={{ display:'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Start a SAST Scan</h2>
            <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  marginRight:20,
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#fff',
                  borderWidth: 1,
                  color: '#fff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight:10 }} />
                Back to Scan List
              </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white'  }}>Scan Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Scan Name"
                autoComplete="scanName"
                className="white-input"
                onChange={(e) => setScanName(e.target.value)}
                style={{ width: '100%' }}
                maxLength={100}
              />
              {errors.scanName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.scanName}</div>}
            </CInputGroup>       

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Select application</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="scanType"
                className="white-input"
                onChange={(e) => setApplicationId(e.target.value)}
                style={{ width: '100%' }}
              >
                {applications.map(application => (
                  <option key={application._id} value={application._id}>
                    {application.name}
                  </option>
                ))}
              </CFormSelect>
              {errors.applicationId && <div style={{ color: 'red', marginTop: '5px' }}>{errors.applicationId}</div>}
            </CInputGroup>   

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white' }}>Upload the project zip file</CFormLabel><br/>
            <CInputGroup className="" style={{ flexDirection: 'column', }}>
              <CFormInput
                placeholder="Upload project zip file"
                autoComplete="username"
                type="file" 
                className="white-input"
                size="sm"
                id="inputFile"
                accept="application/zip"
                onChange={handleFileChange}
                style={{ width: '100%' }}
              />
              {errors.file && <div style={{ color: 'red', marginTop: '5px' }}>{errors.file}</div>}
            </CInputGroup>          

            {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={startScan}
              disabled={loading}
            >              
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Start Scan'
              }
            </CButton>

            {submissionSuccess &&
              <span style={{color:'green', fontSize:17}}>
                The scan is started. 
                After the scan is complete, the scan report is sent to the email mentioned. <br/>It will also be available 
                in the dashboard under Scans menu. You may leave this window now. 
                <br/> <br/>
                Moving to scans list in 5 seconds...
              </span>
            }
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default StartSASTScan