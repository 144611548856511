import React from 'react';

const MavenGitHubActionsIntegration = () => {
  return (
    <div>
      <h3 className="darkText">GitHub Actions Integration Instructions for Apache Maven with SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure GitHub Secrets</h4>
      <ol>
        <li>Go to your GitHub repository.</li>
        <li>Click on <strong>Settings</strong>.</li>
        <li>Click on <strong>Secrets and variables</strong> {'->'} <strong>Actions</strong>.</li>
        <li>Click on <strong>New repository secret</strong>.</li>
        <li>Add secrets for <strong>INTRUCEPT_CLIENT_ID</strong>, <strong>INTRUCEPT_CLIENT_SECRET</strong>, and <strong>INTRUCEPT_APPLICATION_ID</strong>.</li>
      </ol>

      <h4 className="darkText">Step 2: Create a GitHub Actions Workflow for Maven Build and SAST Scan</h4>
      <p>Create a file named <code>sast.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SAST Check with Build
on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]
  workflow_dispatch:
jobs:
  sast:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v3
    
    - name: Set up JDK 11
      uses: actions/setup-java@v3
      with:
        java-version: '11'
        distribution: 'temurin'
    
    - name: Build with Maven
      run: mvn clean package
    
    - name: Create project zip
      run: zip -r project.zip . -x "*.git*"
    
    - name: Perform SAST Scan
      run: |
        RESPONSE=$(curl -X POST \\
          -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
          -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
          -F "projectZipFile=@project.zip" \\
          -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
          -F "scanName=SAST Scan from GitHub Actions" \\
          -F "language=java" \\
          https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
        
        echo "SAST Response:"
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable')
        if [ "$VULNS_TABLE" != "null" ] && [ -n "$VULNS_TABLE" ]; then
          echo "$VULNS_TABLE"
        else
          echo "No SAST vulnerabilities found"
        fi
        
        CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
        echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV
    
    - name: Check SAST result
      if: env.canProceed != 'true'
      run: |
        echo "SAST scan failed. Build cancelled."
        exit 1
    
    - name: Rebuild with Maven
      if: env.canProceed == 'true'
      run: mvn clean package
    
    - name: Upload artifact
      if: env.canProceed == 'true'
      uses: actions/upload-artifact@v3
      with:
        name: sast-project-files
        path: target/*.jar`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Create a GitHub Actions Workflow for Maven Build and SCA Scan</h4>
      <p>Create a file named <code>sca.yml</code> in the <code>.github/workflows</code> directory of your project repository with the following content:</p>

      <pre>
        <code>
{`name: SCA Check with Build
on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]
  workflow_dispatch:
jobs:
  sca:
    runs-on: ubuntu-latest
    steps:
    - uses: actions/checkout@v3
    
    - name: Set up JDK 11
      uses: actions/setup-java@v3
      with:
        java-version: '11'
        distribution: 'temurin'
    
    - name: Build with Maven
      run: mvn clean package
    
    - name: Create project zip
      run: zip -r project.zip . -x "*.git*"
    
    - name: Perform SCA Scan
      run: |
        RESPONSE=$(curl -X POST \\
          -H "Client-ID: \${{ secrets.INTRUCEPT_CLIENT_ID }}" \\
          -H "Client-Secret: \${{ secrets.INTRUCEPT_CLIENT_SECRET }}" \\
          -F "projectZipFile=@project.zip" \\
          -F "applicationId=\${{ secrets.INTRUCEPT_APPLICATION_ID }}" \\
          -F "scanName=SCA Scan from GitHub Actions" \\
          -F "language=java" \\
          https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
        
        echo "SCA Response:"
        VULNS_TABLE=$(echo "$RESPONSE" | jq -r '.vulnsTable // .dependenciesTable')
        if [ "$VULNS_TABLE" != "null" ] && [ -n "$VULNS_TABLE" ]; then
          echo "$VULNS_TABLE"
        else
          echo "No SCA vulnerabilities found"
        fi
        
        CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
        echo "canProceed=$CAN_PROCEED" >> $GITHUB_ENV
    
    - name: Check SCA result
      if: env.canProceed != 'true'
      run: |
        echo "SCA scan failed. Build cancelled."
        exit 1
    
    - name: Rebuild with Maven
      if: env.canProceed == 'true'
      run: mvn clean package
    
    - name: Upload artifact
      if: env.canProceed == 'true'
      uses: actions/upload-artifact@v3
      with:
        name: sca-project-files
        path: target/*.jar`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Workflows</h4>
      <p>These GitHub Actions workflows for Apache Maven build with SAST and SCA scanning do the following:</p>
      <ol>
        <li>Trigger on push or pull request to the main branch, or manual dispatch.</li>
        <li>Set up the build environment (JDK 11).</li>
        <li>Build the project using Maven.</li>
        <li>Create a zip file of the project for scanning.</li>
        <li>Perform the respective scan (SAST or SCA) using the Intrucept API.</li>
        <li>Display the scan results or "No vulnerabilities found" if none are detected.</li>
        <li>Proceed with the build if the scan passes, otherwise fail the workflow.</li>
        <li>Upload the built artifact if successful.</li>
      </ol>

      <p><strong>Note:</strong> Remember to adjust the Java version, branch names, and any other project-specific settings to match your project's requirements. Ensure that you have added the required secrets (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID) to your GitHub repository before running these workflows.</p>
    </div>
  );
};

export default MavenGitHubActionsIntegration;