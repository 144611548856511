import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  CButton,  
  CFormInput,
  CInputGroup,
  CFormLabel,
  CFormSelect,
} from '@coreui/react';

import {  useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { validateEntityName, validateURL, validateHost } from '../../../../helpers/validation';

const EditApplication = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [applicationName, setApplicationName] = useState("");
  const [applicationType, setApplicationType] = useState('');
  const [assetGroup, setAssetGroup] = useState('');
  const [businessUnit, setBusinessUnit] = useState('');
  const [businessOwner, setBusinessOwner] = useState('');
  const [businessImpact, setBusinessImpact] = useState('');
  const [testingStatus, setTestingStatus] = useState('');
  const [url, setURL] = useState('');
  const [host, setHost] = useState('');
  const [description, setDescription] = useState('');

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [application, setApplication] = useState({});

  const [users, setUsers] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [assetGroups, setAssetGroups] = useState([]);

  const [errors, setErrors] = useState({
    applicationName: '',
    applicationType: '',
    businessUnit: '',
    assetGroup: '',
    businessOwner: '',
    url: '',
    host: '',
    description: '',
    general: '',
  });


  const appTypes = ['Website', 'Desktop Application', 'Mobile Application', 'Web Service', 'Others'];
  const businessImpacts = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
  const testingStatuses = ['IN PROGRESS', 'COMPLETED', 'YET TO START', 'UNDER REVIEW'];

  useEffect(() => {

    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theApplicationId = arr[1];
    loadApplicationDetails(theApplicationId);
    fetchUsers();
    fetchBusinessUnits();
    fetchAssetGroups();
  }, []);

  const loadApplicationDetails = async (theApplicationId) => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
  
    try {
      const response = await axios.get(`/api/v1/applications/${theApplicationId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setApplication(response.data.application);
      setApplicationName(response.data.application.name);
      setApplicationType(response.data.application.type);
      setAssetGroup(response.data.application.assetGroup);
      setBusinessUnit(response.data.application.businessUnit);
      setBusinessOwner(response.data.application.businessOwner);
      setBusinessImpact(response.data.application.businessImpact);
      setTestingStatus(response.data.application.testingStatus);
      setURL(response.data.application.url);
      setHost(response.data.application.host);
      setDescription(response.data.application.description);
  
    } catch (error) {
      console.error("Error loading application details:", error);
      setErrors(prevErrors => ({ ...prevErrors, general: "Failed to load application details. Please try again." }));
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('/api/v1/organizations/users/all/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data.users);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBusinessUnits = async () => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('/api/v1/organizations/business-units/all/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBusinessUnits(response.data.businessUnits);
    } catch (error) {
      console.error("Error fetching business units:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssetGroups = async () => {
  setLoading(true);
  const token = localStorage.getItem('ASIToken');
  try {
    const response = await axios.get('/api/v1/organizations/asset-groups/all/all', {
      headers: { Authorization: `Bearer ${token}` },
    });
    setAssetGroups(response.data.assetGroups);
  } catch (error) {
    console.error("Error fetching asset groups:", error);
  } finally {
    setLoading(false);
  }
};

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      applicationName: '',
      applicationType: '',
      businessUnit: '',
      assetGroup: '',
      businessOwner: '',
      url: '',
      host: '',
      description: '',
      general: '',
    };

    if (!applicationName) {
      newErrors.applicationName = 'Application name is mandatory.';
      isValid = false;
    } else if (!validateEntityName(applicationName)) {
      newErrors.applicationName = 'Invalid application name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).';
      isValid = false;
    }

    if (!applicationType) {
      newErrors.applicationType = 'Application type is mandatory.';
      isValid = false;
    }

    if (!businessUnit) {
      newErrors.businessUnit = 'Business unit is mandatory.';
      isValid = false;
    }

    if (!assetGroup) {
      newErrors.assetGroup = 'Asset group is mandatory.';
      isValid = false;
    }

    if (!businessOwner) {
      newErrors.businessOwner = 'Business owner is mandatory.';
      isValid = false;
    }

    if (url && !validateURL(url)) {
      newErrors.url = 'Invalid URL format.';
      isValid = false;
    }

    if (host && !validateHost(host)) {
      newErrors.host = 'Invalid host. Please enter a valid URL, hostname, or IP address.';
      isValid = false;
    }

    if (description.length > 10000) {
      newErrors.description = 'Description must not exceed 10000 characters.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const editApplication = async () => {
    if (validateForm()) {
      setSubmitting(true);
      const bearerToken = localStorage.getItem('ASIToken');
  
      const requestData = {
        name: applicationName,
        type: applicationType,
        businessImpact: businessImpact,
        businessUnit: businessUnit,
        assetGroup: assetGroup,
        testingStatus: testingStatus,
        description: description,
        url: url,
        host: host,
        businessOwner: businessOwner,
      };
  
      try {
        const response = await axios.put(`/api/v1/applications/${application._id}`, requestData, {
          headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        const data = response.data;
  
        if (data.hasOwnProperty('error')) {
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
        } else if (data.hasOwnProperty('err')) {
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          toast('Application updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate('/applications');
        }
      } catch (error) {
        console.error(error);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      } finally {
        setSubmitting(false);
      }
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/applications');
  };

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Editing Application</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Applications
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Application Name"
                autoComplete="applicationName"
                className="white-input"
                onChange={(e) => setApplicationName(e.target.value)}
                value={applicationName}
                style={{ width: '100%' }}
                maxLength={100}
              />
              {errors.applicationName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.applicationName}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Type</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="applicationType"
                className="white-input"
                onChange={(e) => setApplicationType(e.target.value)}
                value={applicationType}
                style={{ width: '100%' }}
              >

                
                {appTypes.map(appType => (
                  <option key={appType} value={appType}>
                    {appType}
                  </option>
                ))}
              </CFormSelect>
              {errors.applicationType && <div style={{ color: 'red', marginTop: '5px' }}>{errors.applicationType}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                value={businessUnit._id || ''}
                style={{ width: '100%' }}
              >
                {businessUnit === null && (
                  <option value="" disabled>
                    Please select
                  </option>
                )}
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
              {errors.businessUnit && <div style={{ color: 'red', marginTop: '5px' }}>{errors.businessUnit}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Asset Group</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="assetGroup"
                className="white-input"
                onChange={(e) => setAssetGroup(e.target.value)}
                value={assetGroup._id || ''}
                style={{ width: '100%' }}
              >
                {assetGroup === null && (
                  <option value="" disabled>
                    Please select
                  </option>
                )}
                {assetGroups.map(assetGroup => (
                  <option key={assetGroup._id} value={assetGroup._id}>
                    {assetGroup.name}
                  </option>
                ))}
              </CFormSelect>
              {errors.assetGroup && <div style={{ color: 'red', marginTop: '5px' }}>{errors.assetGroup}</div>}
            </CInputGroup>

           
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Owner</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessOwner"
                className="white-input"
                value={businessOwner._id || ''}
                onChange={(e) => setBusinessOwner(e.target.value)}
                style={{ width: '100%' }}
              >
                {(businessOwner === null || businessOwner === '') && (
                  <option value="" disabled selected>
                    Please select
                  </option>
                )}
                {users.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}  ({user.email})
                  </option>
                ))}
              </CFormSelect>
              {errors.businessOwner && <div style={{ color: 'red', marginTop: '5px' }}>{errors.businessOwner}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Impact</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessImpact"
                className="white-input"
                value={businessImpact}
                onChange={(e) => setBusinessImpact(e.target.value)}
                style={{ width: '100%' }}
              >
                {businessImpacts.map(businessImpact => (
                  <option key={businessImpact} value={businessImpact}>
                    {businessImpact}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Testing Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="testingStatus"
                className="white-input"
                value={testingStatus}
                onChange={(e) => setTestingStatus(e.target.value)}
                style={{ width: '100%' }}
              >
                {testingStatuses.map(testingStatus => (
                  <option key={testingStatus} value={testingStatus}>
                    {testingStatus}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>URL</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="URL"
                autoComplete="url"
                className="white-input"
                value={url}
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.url && <div style={{ color: 'red', marginTop: '5px' }}>{errors.url}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Host</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Host"
                autoComplete="host"
                className="white-input"
                value={host}
                onChange={(e) => setHost(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.host && <div style={{ color: 'red', marginTop: '5px' }}>{errors.host}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Description</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <textarea
                id="formTextarea"
                placeholder="Description"
                autoComplete="description"
                className="form-control white-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                maxLength={10000}
              />
              <div style={{ color: 'white', marginTop: '5px' }}>{description.length}/10000</div>
              {errors.description && <div style={{ color: 'red', marginTop: '5px' }}>{errors.description}</div>}
            </CInputGroup>

            {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={editApplication}
              disabled={submitting}
            >
              {submitting ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save Application'
              }
            </CButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditApplication;
