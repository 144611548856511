import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress, Menu, MenuItem } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";
import { FaCaretDown } from "react-icons/fa";
import CustomReportModal from './CustomReportModal';

import Chart from 'react-apexcharts'

const ScanDetails = () => {

  const location = useLocation();  

  const [searchText, setSearchText] = useState('');
  const [filterList, setFilterList] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    severities: [],
    titles:[],
    targetURLs:[],
  });
  const [severityCounts, setSeverityCounts] = React.useState([0,0,0,0]);

  const [searchInput, setSearchInput] = useState('');
  const prevSearchInput = useRef(searchInput);

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()
  const [scanId, setScanId] = useState('')
  const [userId, setUserId] = useState('')
  const [dastScan, setDastScan] = useState(null)
  const [onLoading, setOnLoading] = useState(true);
  const [onTableLoading, setOnTableLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [headerModalIsOpen, setHeaderModalIsOpen] = React.useState(false);
  const [findingsModalIsOpen, setFindingsModalIsOpen] = React.useState(false);
  const [inputFieldsModalIsOpen, setInputFieldsModalIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customReportModalOpen, setCustomReportModalOpen] = useState(false);
  const [currentVulnerability, setCurrentVulnerability] = React.useState(null);

  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [exportingPDF, setExportingPDF] = useState(false);

  const [showUrls, setShowUrls] = useState(false);

  // Function to toggle visibility
  const toggleUrlsVisibility = () => {
    setShowUrls(!showUrls);
  };

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '20%',
      width: '60%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow',
      zIndex: 1000,
    },
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const handleGenerateCustomReport = (selectedOptions) => {


    const optionsString = JSON.stringify(selectedOptions);

    const urlToOpen = global.reportAPIURL + '/api/v1/download/dast-report-download/' + scanId + '/' + userId + '/custom/' + optionsString;
    window.open(urlToOpen, '_blank');

  };


  ChartJS.register(ArcElement, Tooltip, Legend);  

  useEffect(() => {

    window.scrollTo(0, 0);
    setOnLoading(true);
  
    var arr = location.search.split('=');
    var theScanId = arr[1];
    setScanId(theScanId);
  
    loadScanDetails(theScanId, 0, rowsPerPage, '', {});
  
    var user = JSON.parse(localStorage.getItem('ASIUser'));
    setUserId(user._id);
  }, []);


  useEffect(() => {
    // Check if the previous search input had text and the current input is empty
    if (prevSearchInput.current && searchInput.length === 0) {
      loadScanDetails(
        scanId,
        0,
        rowsPerPage,
        '',
        filterList
      );
    }

    // Update the ref with the current searchInput after the effect runs
    prevSearchInput.current = searchInput;

  }, [searchInput]);




  

  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {

    setExportingPDF(true);

    // Trigger the corresponding function based on action
    switch (action) {
      case 'summary':
        downloadSummaryReport();
        break;
      case 'detailed':
        downloadDetailedReport();
        break;
      case 'custom':
        downloadCustomReport();
        break;
      case 'xml':
        exportToXML();
        break;
      case 'json':
        exportToJSON();
        break;
      default:
        break;
    }
    setExportingPDF(false);
    handleClose();
  };

  const downloadSummaryReport = () => {

    const urlToOpen = global.reportAPIURL + '/api/v1/download/dast-report-download/' + scanId + '/' + userId + '/summary/none';
    window.open(urlToOpen, '_blank');
  };

  const downloadDetailedReport = () => {
    downloadPDF();
  };

  const downloadCustomReport = () => {
    setCustomReportModalOpen(true)
  };

  const exportToXML = () => {


    const jsonToXml = (obj, rootName) => {
      let xml = '';
      if (rootName) {
        xml += `<${rootName}>`;
      }

      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          xml += `<${key}>`;
          for (const item of obj[key]) {
            if (typeof item === 'object') {
              xml += jsonToXml(item, 'item');
            } else {
              xml += `<item>${item}</item>`;
            }
          }
          xml += `</${key}>`;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          xml += jsonToXml(obj[key], key);
        } else {
          xml += `<${key}>${obj[key]}</${key}>`;
        }
      }

      if (rootName) {
        xml += `</${rootName}>`;
      }
      return xml;
    };

    const formatXml = (xml) => {
      const PADDING = ' '.repeat(2); // Indentation level
      const reg = /(>)(<)(\/*)/g;
      let pad = 0;
      xml = xml.replace(reg, '$1\r\n$2$3');
      return xml.split('\r\n').map((node) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/)) {
          if (pad !== 0) {
            pad -= 1;
          }
        } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
          indent = 1;
        } else {
          indent = 0;
        }

        const padding = PADDING.repeat(pad);
        pad += indent;
        return padding + node;
      }).join('\r\n');
    };

    const xmlString = jsonToXml(dastScan, 'DASTScan');
    const formattedXml = formatXml(xmlString);
    const blob = new Blob([formattedXml], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'dastScan.xml';
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL object

  };

  const exportToJSON = () => {

    const jsonString = JSON.stringify(dastScan, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'DAST_Scan_Report_' + dastScan._id + '.json';
    link.click();
    URL.revokeObjectURL(url);

  };


const loadScanDetails = async (theScanId, page, rowsPerPage, searchText, filterList) => {

  setOnLoading(true);

  try {

    // Build query parameters for search and filters
    const queryParams = new URLSearchParams();

    if (searchText) {
      queryParams.append('search', searchText);
    }

    if (Object.keys(filterList).length > 0) {
      queryParams.append('filterList', JSON.stringify(filterList));
    }

    const queryString = queryParams.toString();
    const url = `api/v1/dast-scans/details/${theScanId}/${page}/${rowsPerPage}${queryString ? `?${queryString}` : ''}`;

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setDastScan(response.data.dastScan);
    setCount(response.data.totalCount);
    setFilterOptions(response.data.filterOptions);
    setSeverityCounts(response.data.severityCounts);

  } catch (error) {
    console.error('Error loading scan details:', error);
  }

  setOnLoading(false);
};




  const downloadPDF = async (e) => {

    
    const urlToOpen = global.reportAPIURL + '/api/v1/download/dast-report-download/' + scanId + '/' + userId + '/full/none';

    window.open(urlToOpen, '_blank');

  }

  const openRemediationModal = async (value) => {

    setCurrentVulnerability(value);

    setModalIsOpen(true);
  };

  const closeModal = async () => {

    setModalIsOpen(false);
  };


  const openHeaderModal = async (value) => {

    setCurrentVulnerability(value);

    setHeaderModalIsOpen(true);
  };

  const closeHeaderModal = async () => {

    setHeaderModalIsOpen(false);
  };

  const openFindingsModal = async (value) => {

    setCurrentVulnerability(value);

    setFindingsModalIsOpen(true);
  };

  const closeFindingsModal = async () => {

    setFindingsModalIsOpen(false);
  };



  const openInputFieldsModal = async (value) => {

    setCurrentVulnerability(value);

    setInputFieldsModalIsOpen(true);
  };

  const closeInputFieldsModal = async () => {

    setInputFieldsModalIsOpen(false);
  };

  const goBack = async () => {

    navigate('/dast-scans')
  }

  



  const columns = [
    {
      label: "#",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Target URL",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.targetURLs,
          logic: (value, filters) => {
              if (filters.length === 0) return false;
              return !filters.includes(value);
          }
      },
      filterList: filterList['Target URL'] || []
      }
    },
    {
      label: "Issue Title",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.titles,
          logic: (value, filters) => {
              if (filters.length === 0) return false;
              return !filters.includes(value);
          }
      },
      filterList: filterList['Issue Title'] || [] 
      }
    },
    {
      label: "Description",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Severity",
      options: {
        filter: true,
        download: true,
        filterOptions: {
          names: filterOptions.severities,
          logic: (value, filters) => {
              if (filters.length === 0) return false;
              return !filters.includes(value);
          }
        },
        filterList: filterList['Severity'] || [],
        customBodyRender: (value, tableMeta, updateValue) => {

          let bgColor;
          let theColor;

          if (value.toUpperCase() == 'CRITICAL') {

            bgColor = '#990000';
            theColor = '#fff';

          } else if (value.toUpperCase() == 'HIGH') {

            bgColor = '#FF6600';
            theColor = '#fff';


          } else if (value.toUpperCase() == 'MEDIUM') {

            bgColor = '#FFCC00';
            theColor = 'black';

          } else if (value.toUpperCase() == 'LOW') {

            bgColor = '#669933';
            theColor = '#000';

          }

          return (
            <div style={{
              display: "flex",
              alignItems: "flex-start",
            }} >

              <text style={{
                padding: 5, backgroundColor: bgColor, color: theColor, width: 120,
                textAlign: 'center', borderRadius: 10, fontSize: 13
              }}>{value}</text>

            </div>
          )
        }
      }
    },
    {
      label: "OWASP",
      options: {
        filter: false,        
        download: true,
        display:dastScan && dastScan.application.owaspTop10Enabled?true:false,
        customBodyRender: (value, tableMeta, updateValue) => {


          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fff', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---


              </div>
            )
          }


        }
      }
    },
    {
      label: "CWE",
      options: {
        filter: false,
        download: true,        
        customBodyRender: (value, tableMeta, updateValue) => {


          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fff', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---


              </div>
            )
          }
        }
      }
    },
    {
      label: "PCI DSS Violations",
      options: {
        filter: false,
        download: true,        
        display:dastScan && dastScan.application.pciDSSEnabled?true:false,
        customBodyRender: (value, tableMeta, updateValue) => {

          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fffde7', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---

              </div>
            )
          }


        }
      }
    },
    {
      label: "Evidence",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openFindingsModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>
                  View Evidence
              </CButton>

            </div>
          )
        }
      }
    },
    {
      label: "Headers",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openHeaderModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>View Headers
              </CButton>

            </div>
          )
        }
      }
    },
    {
      label: "Input Fields",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openInputFieldsModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>View Input Fields
              </CButton>

            </div>
          )
        }
      }
    },
    {
      label: "Remediations",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openRemediationModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>View Remediations
              </CButton>

            </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })
  

  const options = {
    filterType: "multiselect",
    responsive: "stacked",
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
          <div style={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: '10px',
              padding: '8px 16px'
          }}>
              <input
                  type="text"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                          setSearchText(searchInput);
                          setPage(0);
                          loadScanDetails(
                              scanId,
                              0,
                              rowsPerPage,
                              searchInput,
                              filterList
                          );
                      }
                  }}
                  placeholder="Search vulnerabilities..."
                  style={{
                      padding: '8px 12px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      width: '300px',
                      fontSize: '14px',
                      color:'#000',
                  }}
              />
              <button
                  onClick={() => {
                      setSearchText(searchInput);
                      setPage(0);
                      loadScanDetails(
                          scanId,
                          0,
                          rowsPerPage,
                          searchInput,
                          filterList
                      );
                  }}
                  style={{
                      padding: '8px 16px',
                      borderRadius: '4px',
                      border: 'none',
                      backgroundColor: '#1976d2',
                      color: 'white',
                      cursor: 'pointer',
                      fontSize: '14px'
                  }}
              >
                  Search
              </button>
              {searchInput && (
                  <button
                      onClick={() => {
                          setSearchInput('');
                          setSearchText('');
                          setPage(0);
                          loadScanDetails(
                              scanId,
                              0,
                              rowsPerPage,
                              '',
                              filterList
                          );
                      }}
                      style={{
                          padding: '8px 16px',
                          borderRadius: '4px',
                          border: 'none',
                          backgroundColor: '#d32f2f',
                          color: 'white',
                          cursor: 'pointer',
                          fontSize: '14px'
                      }}
                  >
                      Clear
                  </button>
              )}
          </div>
      );
  },
    onTableChange: (action, tableState) => {
      console.log('Table Action:', action, 'State:', tableState);
  
      switch (action) {
        case 'changePage':
          setPage(tableState.page);
          loadScanDetails(
            scanId, 
            tableState.page, 
            tableState.rowsPerPage, 
            searchText,
            filterList
          );
          break;
  
        case 'changeRowsPerPage':
          setRowsPerPage(tableState.rowsPerPage);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            filterList
          );
          break;
  
        case 'search':
        case 'searchTextUpdate':
          if (tableState.searchText !== searchText) {
            setSearchText(tableState.searchText);
            setPage(0);
            loadScanDetails(
              scanId,
              0,
              tableState.rowsPerPage,
              tableState.searchText,
              filterList
            );
          }
          break;
  
        case 'filterChange':
          const newFilterList = {};
          tableState.filterList.forEach((filter, index) => {
            if (filter && filter.length) {
              newFilterList[columns[index].label] = filter;
            }
          });
          
          setFilterList(newFilterList);
          setPage(0);
          loadScanDetails(
            scanId,
            0,
            tableState.rowsPerPage,
            searchText,
            newFilterList
          );
          break;

          case 'resetFilters':
            setFilterList({});
            setPage(0);
            loadScanDetails(
                scanId,
                0,
                tableState.rowsPerPage,
                searchText,
                {}
            );
            break;
      }
    },
    
    textLabels: {
      body: {
        noMatch: onLoading ? 'Loading data...' : 'No vulnerabilities found',
      }
    },
    filter: true,
    search: true,
    searchOpen:true,
    searchPlaceholder: 'Search vulnerabilities...',    
    filterType: 'multiselect',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    elevation: 0,
    onSearchChange: (searchText) => {
      console.log('Search Text:', searchText);
      setSearchText(searchText || '');
      setPage(0);

      if(searchText.length > 1){
      loadScanDetails(
          scanId,
          0,
          rowsPerPage,
          searchText || '',
          filterList
      );
    }
  },
  };


  var tableData = [];

  if (dastScan && dastScan.vulnerabilities) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {

      var vuln = dastScan.vulnerabilities[i];

      var dataItem = [];

      dataItem.push(((page) * 10) + (i+1));

      dataItem.push(vuln.targetURL);

      dataItem.push(vuln.title);

      dataItem.push(vuln.vulnDescription);

      dataItem.push(vuln.severity);

      if (vuln.scanRule.owasp && vuln.scanRule.owasp.length > 0) {
        dataItem.push(vuln.scanRule.owasp);
      } else {
        dataItem.push('---');
      }

      if (vuln.scanRule.cwe && vuln.scanRule.cwe.length > 0) {
        dataItem.push(vuln.scanRule.cwe);
      }
      else {
        dataItem.push('---');
      }

      if (vuln.pciViolations && vuln.pciViolations.length > 0) {
        dataItem.push(vuln.pciViolations);
      }
      else {
        dataItem.push('---');
      }
      
      dataItem.push(vuln); // for findings    
      dataItem.push(vuln); // for headers    
      dataItem.push(vuln); // for input fields      

      dataItem.push(vuln); // for remediations 

      tableData.push(dataItem);

    }
  }


  var lowCount = 0;
  var mediumCount = 0;
  var highCount = 0;
  var criticalCount = 0;

  if (dastScan) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {

      if (dastScan.vulnerabilities[i].severity == 'CRITICAL') {

        criticalCount++;

      } else if (dastScan.vulnerabilities[i].severity == 'HIGH') {

        highCount++;

      } else if (dastScan.vulnerabilities[i].severity == 'MEDIUM') {

        mediumCount++;

      } else if (dastScan.vulnerabilities[i].severity == 'LOW') {

        lowCount++;
      }

    }
  }

  var dataArray = [criticalCount, highCount, mediumCount, lowCount];

  var labelsArray = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];

  const bgColorArray = [
    '#990000', '#FF6600', '#FFCC00', '#669933',
  ];


  const vulnDistrochartOptions = {
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
      onItemHover: {
        highlightDataSeries: false
      }
    },

  };

  //const vulnDistroChartSeries = dataArray;
  const vulnDistroChartSeries = severityCounts;

  ///Vuln type chart
  var owaspTop10Count = 0;
  var sans25Count = 0;
  var othersCount = 0;

  var sans25CWEs = ['CWE-787', 'CWE-79', 'CWE-89', 'CWE-416', 'CWE-78', 'CWE-20', 'CWE-125', 'CWE-22', 'CWE-352', 'CWE-434', 'CWE-862', 'CWE-476', 'CWE-287',
    'CWE-190', 'CWE-502', 'CWE-77', 'CWE-119', 'CWE-798', 'CWE-918', 'CWE-306', 'CWE-362', 'CWE-269', 'CWE-94', 'CWE-863', 'CWE-276'];


  if (dastScan) {

    for (var i = 0; i < dastScan.vulnerabilities.length; i++) {

      if (dastScan.vulnerabilities[i].owasp && dastScan.vulnerabilities[i].owasp.length > 0) {
        owaspTop10Count = owaspTop10Count + dastScan.vulnerabilities[i].owasp.length;
      }

      if (dastScan.vulnerabilities[i].cwe && dastScan.vulnerabilities[i].cwe.length > 0) {

        // Check if any sans25CWE is present in the whole cwe value
        const hasMatchingCWE = sans25CWEs.some(cwe => dastScan.vulnerabilities[i].cwe.includes(cwe));

        // Increment the count if there is a matching CWE
        if (hasMatchingCWE) {
          sans25Count++;
        }
      } else {
        othersCount++;
      }


    }
  }



  var dataArray1 = [owaspTop10Count, sans25Count, othersCount];

  var labelsArray1 = ['OWASP Top 10', 'SANS 25', 'Others'];

  const bgColorArray1 = [
    '#00BFFF', '#9370DB', '#008080',
  ];


  const vulnTypesChartOptions = {
    labels: labelsArray1,
    colors: bgColorArray1,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
      onItemHover: {
        highlightDataSeries: false
      }
    },

  };

  const vulnTypesChartSeries = dataArray1;


  console.log('onLoading:', onLoading)

  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden' }}>

      <>

        {onLoading ?

          <div style={{
            width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: '2%'
          }}>

            <ShimmerTable row={5} col={10} />
          </div>

          :

          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

            <div style={{ marginBottom: '2rem', }}>
              <h2>{dastScan.projectName}</h2>
              <hr />

              <table style={{width:600}}>

                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan ID </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scanId}
                  </td>
                </tr>


                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
<h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Name </h6>
</td>
<td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

  {dastScan.scanName}
</td>
</tr>

                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Application Name </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {dastScan.application.name}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Primary URL </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {dastScan.mainURL}

                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>URLS Scanned</h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {dastScan.urls.length}

                     {/* View URLs link */}
      {!showUrls && (
        <div>
          <button 
            style={{ color: 'white', cursor: 'pointer', background: 'none', border: 'none', textDecoration:'underline',padding:0, fontSize:12  }}
            onClick={toggleUrlsVisibility}
          >View URLs</button>
        </div>
      )}

      {/* Conditionally render the URLs */}
      {showUrls && (
        <div>
          {/* Display URLs separated by new lines */}
          <div style={{ whiteSpace: 'pre-line', marginTop: 10, }}>
            {dastScan.urls.map((item, index) => (
              <div key={index} style={{fontStyle:'italic'}}>{item.url}</div>
            ))}
          </div>

          {/* Hide URLs link */}
          <div>
            <button 
              style={{ color: 'white', cursor: 'pointer', background: 'none', border: 'none', textDecoration:'underline', marginTop: 10, padding:0, fontSize:12 }}
              onClick={toggleUrlsVisibility}
            >
              Hide URLs
            </button>
          </div>
        </div>
      )}
                  </td>
                </tr>


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Vulnerabilities</h6>

                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {count}

                  </td>
                </tr>

                


                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Started At</h6>

                  </td>
                  <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>

                    {(new Date(dastScan.createdAt)).toLocaleDateString('en-US')} - {(new Date(dastScan.createdAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>



                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Status</h6>

                  </td>
                  <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>

                  {dastScan.status ?dastScan.status.toUpperCase():'---'}

                  </td>
                </tr>


{dastScan.scanCompletedAt &&

                <tr>

                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', width: 400, background: '#fff' }}>

                    <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Completed At</h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', width: 400 }}>

                    {(new Date(dastScan.scanCompletedAt)).toLocaleDateString('en-US')} - {(new Date(dastScan.scanCompletedAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>

}

              </table>


            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#ffffff',
                  borderWidth: 1,
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Scans List
              </CButton>

              <div style={{ display: 'flex' }}>

{dastScan.status == 'Completed' &&
<CButton
  onClick={handleClick}
  style={{
    width: 300,
    marginBottom: '2%',
    borderWidth: 0,
    fontSize: 20,
    marginTop: 10,
    background: '#e50202'
  }}
  color="primary"
  className="px-3"
>

  {exportingPDF ?
    <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
    :
    <>

      Download Report <FaCaretDown size={25} style={{ color: '#ffffff', marginLeft: 10 }} />
    </>
  }

</CButton>
}


<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
  style={{ width: '100%', color: 'white' }}
>
  <MenuItem onClick={() => handleMenuItemClick('summary')} style={{ width: '100%', color: 'white' }}>Summary Report (PDF)</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('detailed')} style={{ width: '100%', color: 'white' }}>Detailed Report (PDF)</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('custom')} style={{ width: '100%', color: 'white' }}>Custom Report (PDF)</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('xml')} style={{ width: '100%', color: 'white' }}>Export to XML</MenuItem>
  <MenuItem onClick={() => handleMenuItemClick('json')} style={{ width: '100%', color: 'white' }}>Export to JSON</MenuItem>
</Menu>

</div>

            </div>
          </div>

        }





        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



          <h4 style={{ marginTop: 30 }}>Severity Scoring</h4>
          <hr />

          <span><text style={{ fontWeight: 'bold', backgroundColor: '#990000', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>CRITICAL</text> Vulnerabilities that can be exploited remotely, leading to immediate and widespread impact on the confidentiality,
            integrity, and availability of systems or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor: '#FF6600', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>HIGH</text> Vulnerabilities that can be exploited but require some form of user interaction or other
            preconditions to be met, potentially resulting in significant impact on system or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor: '#FFCC00', color: '#000', padding: 5, borderRadius: 5, marginRight: 20 }}>MEDIUM</text> Vulnerabilities that could result in a compromise of system or data security,
            but require more complex exploitation techniques or have limited impact.</span>

          <span style={{ marginTop: 15 }}><span style={{ fontWeight: 'bold', backgroundColor: '#669933', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>LOW</span> Vulnerabilities that have a low likelihood of being exploited or have minimal impact on system or data security.</span>







        </div>



        {onLoading == true ?

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 50 }}>

            <ShimmerTable row={8} col={10} />


          </div>
          :

          <>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 50, height: 500 }}>

{dastScan.vulnerabilities.length > 0 &&
              <div style={{
                width: '30%', marginLeft: '10%', marginRight: '10%', display: 'flex', flexDirection: 'column',
                marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
              }}>

                <h4 style={{ color: '#fff', textAlign: 'center' }}>Severity Distribution</h4>
                <hr />

                <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} type="pie" />

              </div>
}

{dastScan.vulnerabilities.length > 0 &&


              <div style={{
                width: '30%', marginLeft: '10%', marginRight: '10%', display: 'none', flexDirection: 'column',
                marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
              }}>

                <h4 style={{ color: '#fff', textAlign: 'center' }}>Issue Types</h4>
                <hr />

                <Chart options={vulnTypesChartOptions} series={vulnTypesChartSeries} type="donut" />


              </div>
}


            </div>



          </>

        }




        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%'
        }}>

          {onLoading &&
            <ShimmerTable row={8} col={10} />

          }


          {!onLoading &&

            <>

              {tableData.length > 0 &&

                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    style={{ height: "57vh" }}
                    data={tableData}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              }

            </>


          }

        </div>


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >

          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeModal} >
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability &&

            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>


              <h5 style={{ color: '#000' }}><strong>Issue</strong>: {currentVulnerability.scanRule.ruleName}</h5>
              <h5 style={{ color: '#000' }}><strong>Severity</strong>: {currentVulnerability.scanRule.severity}</h5>

              <p style={{ color: '#000' }}><strong>Vulnerability Description</strong>: {currentVulnerability.scanRule.vulnDescription}</p>

              <hr />

              <h5 style={{ color: '#000', }}>Remediations</h5>
              <hr />

              {currentVulnerability.scanRule.remediations.map((remediation, index) => (

                <div style={{ backgroundColor: '#ebedef', padding: 10, marginTop: 10, borderRadius: 15 }}>

                  <h5 style={{ color: '#000', fontSize: 16, fontWeight: 'bold' }}>{remediation.remediationHeading}</h5>
                  <p style={{ color: '#000', fontSize: 16, fontWeight: 'normal' }}>{remediation.remediationContent}</p>

                </div>
              ))}

            </div>
          }


        </Modal>


        <Modal
          isOpen={headerModalIsOpen}
          onRequestClose={closeHeaderModal}
          style={customStyles}
          contentLabel="Headers"
        >

          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeHeaderModal} >
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability &&

            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>

              <h5 style={{ color: '#000' }}><strong>Target URL</strong>:{currentVulnerability.targetURL}</h5>
              <h6 style={{ color: '#000' }}>Headers</h6>

              <pre>
                {currentVulnerability.headers
                  ? JSON.stringify(currentVulnerability.headers, null, 2)
                  : ''}
              </pre>

            </div>
          }


        </Modal>

        <Modal
          isOpen={findingsModalIsOpen}
          onRequestClose={closeFindingsModal}
          style={customStyles}
          contentLabel="Findings"
        >

          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeFindingsModal} >
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability &&

            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>

              <h5 style={{ color: '#000' }}><strong>Target URL</strong>:{currentVulnerability.targetURL}</h5>
              <h6 style={{ color: '#000' }}>Findings</h6>

{currentVulnerability.details ?
              <pre>
                {currentVulnerability.details
                  ? JSON.stringify(currentVulnerability.details, null, 2)
                  : ''}
              </pre>
              :
<pre>
                {currentVulnerability.headers
                  ? JSON.stringify(currentVulnerability.headers, null, 2)
                  : ''}
              </pre>

                }


            </div>
          }


        </Modal>


        <Modal
  isOpen={inputFieldsModalIsOpen}
  onRequestClose={closeInputFieldsModal}
  style={customStyles}
  contentLabel="Input Fields"
>
  <button
    style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }}
    onClick={closeInputFieldsModal}
  >
    <AiFillCloseCircle size={30} color={'#000'} />
  </button>

  {currentVulnerability && (
    <div className="modalWindow" style={{ backgroundColor: '#E1E1E1', padding: '20px' }}>
      <h5 style={{ color: '#000' }}>
        <strong>Target URL:</strong> {currentVulnerability.targetURL}
      </h5>
      <h6 style={{ color: '#000', marginTop: '20px' }}>
        <strong>Input Fields</strong>
      </h6>

      {/* Display input fields in a more readable format */}
      {currentVulnerability.inputs && currentVulnerability.inputs.length > 0 ? (
        <table style={{ width: '100%', marginTop: '10px', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ paddingLeft: '5px',textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Name</th>
              <th style={{ paddingLeft: '5px', textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Type</th>
              <th style={{ paddingLeft: '5px',textAlign: 'left', borderBottom: '1px solid #ccc', paddingBottom: '5px' }}>Value</th>
            </tr>
          </thead>
          <tbody>
            {currentVulnerability.inputs.map((input, index) => (
              <tr key={index}>
                <td style={{ padding: '5px 0', borderBottom: '1px solid #ddd' }}>{input.name || 'N/A'}</td>
                <td style={{ padding: '5px 0', borderBottom: '1px solid #ddd' }}>{input.type}</td>
                <td style={{ padding: '5px 0', borderBottom: '1px solid #ddd' }}>{input.value || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No input fields found</p>
      )}
    </div>
  )}
</Modal>


        <CustomReportModal
          open={customReportModalOpen}
          onClose={() => setCustomReportModalOpen(false)}
          onGenerateReport={handleGenerateCustomReport}
        />


      </>

    </div>
  )
}

export default ScanDetails


