import React from 'react';

const DockerIntegration = () => {
  return (
    <div className="docker-integration">
      <h2 className="darkText">Docker Integration with SAST and SCA Scans</h2>
      <hr/>
      
      <section>
        <h3 className="darkText">Overview</h3>
        <p>This example explains how to integrate SAST (Static Application Security Testing) and SCA (Software Composition Analysis) scans into your Docker build process using external scripts. This approach allows for cleaner Dockerfiles and easier maintenance of scan scripts.</p>
      </section>

      <section>
        <h3 className="darkText">Step 1: Prepare Scan Scripts</h3>
        <p>Create two separate shell scripts for SAST and SCA scans. Place these in the same directory as your Dockerfile.</p>
        
        <h4 className="darkText">SAST Scan Script (sast-scan.sh)</h4>
        <pre>{`
#!/bin/sh

echo "Starting SAST scan..."
PROJECT_ZIP="project.zip"
SAST_API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans"
CLIENT_ID="<your_intrucept_client_id>"
CLIENT_SECRET="<your_intrucept_client_secret>"
APPLICATION_ID="<your_intrucept_application_id>"
SCAN_NAME="New SAST Scan from Docker build"
LANGUAGE="javascript"

echo "Creating project zip..."
zip -r $PROJECT_ZIP . -x "*.git*"

echo "Calling SAST API..."
RESPONSE=$(curl -X POST \\
  -H "Client-ID: $CLIENT_ID" \\
  -H "Client-Secret: $CLIENT_SECRET" \\
  -F "projectZipFile=@$PROJECT_ZIP" \\
  -F "applicationId=$APPLICATION_ID" \\
  -F "scanName=$SCAN_NAME" \\
  -F "language=$LANGUAGE" \\
  $SAST_API_URL)

echo "SAST Response: $RESPONSE"
CAN_PROCEED=$(echo $RESPONSE | jq -r ".canProceed")
VULNS_TABLE=$(echo $RESPONSE | jq -r ".vulnsTable")

echo "Vulnerabilities found during SAST:"
echo "$VULNS_TABLE"
echo "Can proceed: $CAN_PROCEED"

if [ "$CAN_PROCEED" != "true" ]; then
  echo "SAST scan failed. Build cancelled."
  exit 1
fi

echo "SAST scan succeeded. Proceeding with build."
        `}</pre>

        <h4 className="darkText">SCA Scan Script (sca-scan.sh)</h4>
        <pre>{`
#!/bin/sh

echo "Starting SCA scan..."
PROJECT_ZIP="project.zip"
SCA_API_URL="https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans"
CLIENT_ID="<your_intrucept_client_id>"
CLIENT_SECRET="<your_intrucept_client_secret>"
APPLICATION_ID="<your_intrucept_application_id>"
SCAN_NAME="New SCA Scan from Docker build"
LANGUAGE="javascript"

echo "Creating project zip..."
zip -r $PROJECT_ZIP . -x "*.git*"

echo "Calling SCA API..."
RESPONSE=$(curl -X POST \\
  -H "Client-ID: $CLIENT_ID" \\
  -H "Client-Secret: $CLIENT_SECRET" \\
  -F "projectZipFile=@$PROJECT_ZIP" \\
  -F "applicationId=$APPLICATION_ID" \\
  -F "scanName=$SCAN_NAME" \\
  -F "language=$LANGUAGE" \\
  $SCA_API_URL)

echo "SCA Response: $RESPONSE"
CAN_PROCEED=$(echo $RESPONSE | jq -r ".canProceed")
VULNS_TABLE=$(echo $RESPONSE | jq -r ".vulnsTable")

echo "Vulnerabilities found during SCA:"
echo "$VULNS_TABLE"
echo "Can proceed: $CAN_PROCEED"

if [ "$CAN_PROCEED" != "true" ]; then
  echo "SCA scan failed. Build cancelled."
  exit 1
fi

echo "SCA scan succeeded. Proceeding with build."
        `}</pre>
      </section>

      <section>
        <h3 className="darkText">Step 2: Update Dockerfile</h3>
        <p>Modify your Dockerfile to include and run these scripts:</p>
        <pre>{`
# Step 1: Use an official Node.js image as the base image
FROM node:18-alpine AS build

# Step 2: Set the working directory inside the container
WORKDIR /app

# Step 3: Install required tools for the SAST and SCA scans
RUN apk add --no-cache zip curl jq

# Step 4: Copy package.json and package-lock.json (if available)
COPY package*.json ./

# Step 5: Copy the scan scripts
COPY sast-scan.sh sca-scan.sh ./

# Step 6: Make the scripts executable
RUN chmod +x sast-scan.sh sca-scan.sh

# Step 7: Copy the entire project to the working directory
COPY . .

# Step 8: Run both SAST and SCA scan scripts
RUN echo "Executing SAST scan..." \\
    && ./sast-scan.sh \\
    && echo "SAST scan completed." \\
    && echo "Executing SCA scan..." \\
    && ./sca-scan.sh \\
    && echo "SCA scan completed."

# Step 9: Install dependencies
RUN npm install

# Step 10: Build the React app for production
RUN npm run build

# Step 11: Use an official Nginx image to serve the built React app
FROM nginx:stable-alpine

# Step 12: Copy the build output to the Nginx server's web directory
COPY --from=build /app/build /usr/share/nginx/html

# Step 13: Expose the port the app will run on
EXPOSE 80

# Step 14: Start Nginx when the container runs
CMD ["nginx", "-g", "daemon off;"]
        `}</pre>
      </section>

      <section>
        <h3 className="darkText">Explanation</h3>
        <ol>
          <li>We create two separate scripts (sast-scan.sh and sca-scan.sh) for SAST and SCA scans.</li>
          <li>These scripts are copied into the Docker image and made executable.</li>
          <li>The scripts are run before npm install, allowing us to catch potential security issues early.</li>
          <li>If either scan fails (canProceed is false), the Docker build will fail.</li>
          <li>This approach allows for easier maintenance and updating of the scan scripts without modifying the Dockerfile.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Usage</h3>
        <ol>
          <li>Place the sast-scan.sh and sca-scan.sh files in the same directory as your Dockerfile.</li>
          <li>Ensure the scripts have execute permissions (chmod +x sast-scan.sh sca-scan.sh).</li>
          <li>Build your Docker image as usual: docker build -t your-image-name .</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Note</h3>
        <p>Remember to replace the placeholder values (CLIENT_ID, CLIENT_SECRET, APPLICATION_ID) in the scan scripts with your actual Intrucept credentials.</p>
      </section>
    </div>
  );
};

export default DockerIntegration;