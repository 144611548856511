import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";
import { validatePersonName, validateEmail, validatePhoneNumber, validatePassword } from '../../../../helpers/validation';

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Administrator");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userId, setUserId] = useState('');
  const [businessUnits, setBusinessUnits] = useState([]);
  const [userType, setUserType] = useState('user');
  const [businessUnit, setBusinessUnit] = useState('');
  const [theLocation, setTheLocation] = useState('');
  const [theStatus, setTheStatus] = useState('Active');

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });

  const toaster = useRef();
  
  const statuses = ['Active', 'Suspended'];

  useEffect(() => {
    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theUserId = arr[1];
    setUserId(theUserId);
    loadUserDetails(theUserId);
    fetchBusinessUnits();
  }, []);

  const loadUserDetails = async (userId) => {
    setLoading(true);
    try {
        const token = localStorage.getItem('ASIToken');
        const response = await axios.get(`/api/v1/organizations/users/${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });



        const user = response.data.data;
        
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setRole(user.role?user.role:'Administrator');
        setPhoneNumber(user.phoneNumber);
        setBusinessUnit(user.businessUnit);
        setTheLocation(user.location);
        setTheStatus(user.status);
    } catch (error) {
        console.error('Error loading user details:', error);
        // Handle error (e.g., show toast message)
    } finally {
        setLoading(false);
    }
};

  const fetchBusinessUnits = async () => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/business-units/all/all`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setBusinessUnits(response.data.businessUnits);
    setLoading(false);
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
    };

    if (!validatePersonName(firstName)) {
      newErrors.firstName = 'Invalid first name. Only letters and spaces are allowed (max 100 characters).';
      isValid = false;
    }

    if (!validatePersonName(lastName)) {
      newErrors.lastName = 'Invalid last name. Only letters and spaces are allowed (max 100 characters).';
      isValid = false;
    }

    if (!validateEmail(email)) {
      newErrors.email = 'Invalid email address.';
      isValid = false;
    }

    if (password && !validatePassword(password)) {
      newErrors.password = 'Password must be 12-128 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
      isValid = false;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const editApplication = () => {
    if (validateForm()) {
      setSubmitting(true);
      const bearerToken = localStorage.getItem('ASIToken');

      const requestData = {
        userId: userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        phoneNumber: phoneNumber,
        businessUnit: businessUnit,
        userType: userType,
        location: theLocation,   
        status: theStatus
      };

      if (password) {
        requestData.password = password;
      }

      fetch(`${global.backendUrl}/api/v1/organizations/users`, {
        method: 'PUT',  
        headers: {
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
    })    
        .then(response => response.json())
        .then(data => {
          if (data.hasOwnProperty('error')) {
            toast.error(data.error);
            setSubmitting(false);
          }
          else if (data.hasOwnProperty('err')) {
            toast.error("Something went wrong. Please try again");
            setSubmitting(false);
          } else {
            toast.success('User updated');
            navigate('/users');
          }
        })
        .catch(error => {
          console.error(error);
          toast.error("An error occurred. Please try again");
          setSubmitting(false);
        });      
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/users');
  };

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Editing User</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Users
            </CButton>
          </div>

          {loading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>User Role</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="role"
                  className="white-input"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                  style={{ width: '100%' }}
                >
                  <option value="Administrator">Administrator</option>
                  <option value="Application Manager">Application Manager</option>
                  <option value="Tester">Tester</option>
                </CFormSelect>
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>First Name</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="First Name"
                  autoComplete="given-name"
                  className="white-input"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  style={{ width: '100%' }}
                />
                {errors.firstName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.firstName}</div>}
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Last Name</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="Last Name"
                  autoComplete="family-name"
                  className="white-input"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  style={{ width: '100%' }}
                />
                {errors.lastName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.lastName}</div>}
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Email</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="Email"
                  autoComplete="email"
                  className="white-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%' }}
                />
                {errors.email && <div style={{ color: 'red', marginTop: '5px' }}>{errors.email}</div>}
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Password</CFormLabel>
              <p style={{ color: 'white',fontSize:13, }}>Leave this blank if you do not want to change password.</p>
              <p style={{fontSize:12, fontStyle:'italic'}}>The password must be 12-128 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.</p>

              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  type="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  className="white-input"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ width: '100%' }}
                />
                {errors.password && <div style={{ color: 'red', marginTop: '5px' }}>{errors.password}</div>}
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Phone Number</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="Phone Number"
                  autoComplete="tel"
                  className="white-input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  style={{ width: '100%' }}
                />
                {errors.phoneNumber && <div style={{ color: 'red', marginTop: '5px' }}>{errors.phoneNumber}</div>}
              </CInputGroup>        

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="businessUnit"
                  className="white-input"
                  onChange={(e) => setBusinessUnit(e.target.value)}
                  value={businessUnit}
                  style={{ width: '100%' }}
                >
                  {businessUnits.map(businessUnit => (
                    <option key={businessUnit._id} value={businessUnit._id}>
                      {businessUnit.name}
                    </option>
                  ))}
                </CFormSelect>
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Location</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="Location"
                  autoComplete="address-level1"
                  className="white-input"
                  value={theLocation}
                  onChange={(e) => setTheLocation(e.target.value)}
                  style={{ width: '100%' }}
                />
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="status"
                  className="white-input"
                  onChange={(e) => setTheStatus(e.target.value)}
                  value={theStatus}
                  style={{ width: '100%' }}
                >
                  {statuses.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </CFormSelect>
              </CInputGroup>

              <CButton
                style={{
                  width: '100%',
                  marginTop: '3%',
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  background: '#e50202'
                }}
                color="primary"
                className="px-3"
                onClick={editApplication}
                disabled={submitting}
              >
                {submitting ?
                  <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                  :
                  'Save User'
                }
              </CButton>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditUser;