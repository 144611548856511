import React from 'react';

const BitbucketPipelinesIntegration = () => {
  return (
    <div>
      <h3 className="darkText">Bitbucket Pipelines Integration Instructions for SAST and SCA Scans</h3>
      <hr/>

      <h4 className="darkText">Step 1: Configure Bitbucket Pipeline Variables</h4>
      <ol>
        <li>Go to your Bitbucket repository.</li>
        <li>Navigate to <strong>Repository settings</strong> {'->'} <strong>Pipelines</strong> {'->'} <strong>Repository variables</strong>.</li>
        <li>Add the following variables:
          <ul>
            <li><strong>INTRUCEPT_CLIENT_ID</strong></li>
            <li><strong>INTRUCEPT_CLIENT_SECRET</strong></li>
            <li><strong>INTRUCEPT_APPLICATION_ID</strong></li>
            <li><strong>VERCEL_TOKEN</strong> (if deploying with Vercel)</li>
          </ul>
        </li>
        <li>Make sure to mark the secret variables as secured.</li>
      </ol>

      <h4 className="darkText">Step 2: Create Bitbucket Pipelines Configuration File</h4>
      <p>Create a file named <code>bitbucket-pipelines.yml</code> in the root of your repository with the following content:</p>

      <pre>
        <code>
{`pipelines:
  branches:
    main:  # Adjust this to your main branch name
      - step:
          name: SAST and SCA Scan and Deploy
          image: node:16  # Adjust this to your preferred Node.js version
          script:
            - apt-get update && apt-get install -y zip curl jq
            - zip -r project.zip . -x '*.git*'
            # Perform SAST Scan
            - |
              echo "Starting SAST Scan..."
              set +e  # Allow pipeline to continue even if this command fails
              SAST_RESPONSE=$(curl -X POST \\
                -H "Client-ID: $INTRUCEPT_CLIENT_ID" \\
                -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \\
                -F "projectZipFile=@project.zip" \\
                -F "applicationId=$INTRUCEPT_APPLICATION_ID" \\
                -F "scanName=React App SAST Scan from Bitbucket Pipeline" \\
                -F "language=javascript" \\
                https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
              
              SAST_CAN_PROCEED=$(echo $SAST_RESPONSE | jq -r '.canProceed')
              SAST_VULNS_TABLE=$(echo $SAST_RESPONSE | jq -r '.vulnsTable')
              
              echo "Vulnerabilities found during SAST:"
              echo "$SAST_VULNS_TABLE"
              if [ "$SAST_CAN_PROCEED" != "true" ]; then
                echo "SAST scan failed, but continuing to SCA..."
              fi
              set -e  # Re-enable pipeline to fail if next commands fail
            # Perform SCA Scan
            - |
              echo "Starting SCA Scan..."
              SCA_RESPONSE=$(curl -X POST \\
                -H "Client-ID: $INTRUCEPT_CLIENT_ID" \\
                -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \\
                -F "projectZipFile=@project.zip" \\
                -F "applicationId=$INTRUCEPT_APPLICATION_ID" \\
                -F "scanName=React App SCA Scan from Bitbucket Pipeline" \\
                -F "language=javascript" \\
                https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
              
              SCA_CAN_PROCEED=$(echo $SCA_RESPONSE | jq -r '.canProceed')
              SCA_VULNS_TABLE=$(echo $SCA_RESPONSE | jq -r '.vulnsTable')
              
              echo "Vulnerabilities found during SCA:"
              echo "$SCA_VULNS_TABLE"
              
              if [ "$SCA_CAN_PROCEED" != "true" ]; then
                echo "SCA scan failed. Deployment cancelled."
                exit 1
              fi
            # Deploy with Vercel
            - npm install -g vercel
            - vercel --token="$VERCEL_TOKEN" --prod --confirm
          services:
            - docker`}
        </code>
      </pre>

      <h4 className="darkText">Explanation of the Bitbucket Pipelines Configuration</h4>
      <p>This Bitbucket Pipelines configuration does the following:</p>
      <ol>
        <li>Triggers on push to the main branch (adjust as needed).</li>
        <li>Uses a Node.js 16 image (adjust to your preferred version).</li>
        <li>Installs necessary tools (zip, curl, jq).</li>
        <li>Creates a zip file of the project for scanning.</li>
        <li>Performs SAST scan using the Intrucept API:
          <ul>
            <li>Displays the vulnerabilities table from the SAST scan.</li>
            <li>Allows the pipeline to continue even if SAST scan fails.</li>
          </ul>
        </li>
        <li>Performs SCA scan using the Intrucept API:
          <ul>
            <li>Displays the vulnerabilities table from the SCA scan.</li>
            <li>Fails the pipeline if SCA scan detects critical issues.</li>
          </ul>
        </li>
        <li>Deploys the application using Vercel (if SAST and SCA scans pass).</li>
      </ol>

      <p><strong>Note:</strong> Ensure that you have added the required variables (INTRUCEPT_CLIENT_ID, INTRUCEPT_CLIENT_SECRET, INTRUCEPT_APPLICATION_ID, VERCEL_TOKEN) to your Bitbucket Pipeline variables before running this pipeline. Adjust the 'language' parameter in the API calls if your project uses a different primary language. Also, modify the deployment step if you're not using Vercel or if you have a different deployment process.</p>
    </div>
  );
};

export default BitbucketPipelinesIntegration;