import React, { useState, useEffect } from "react";

import axios from 'axios';

import '../style.css';

import { MdOutlineApps } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

const Applications = () => {

  const [appStats, setAppStats] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  useEffect(() => {

    getDashboardData();

  }, []);

  const getDashboardData = async () => {

    setOnLoading(true);

    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get('/api/v1/users/organization-level-applications-stats', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setAppStats(response.data);
    } catch (error) {
      console.error("Error fetching application stats:", error);
    } finally {
      setOnLoading(false);
    }
  };



  return (
    <>


      <div className="dashboardCard" style={{
        display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
        borderRadius: 10, padding: 20, width: '47%'
      }}>


        <span style={{ fontSize: 15 }}>APPLICATIONS</span>
        <hr style={{ borderColor: '#fff' }} />


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

            <MdOutlineApps size={50} style={{ color: 'rgb(115, 102, 255)' }} />

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
              <div style={{ color: 'rgb(115, 102, 255)', fontSize: 25 }}>{appStats.totalApplicationsScanned}</div>
              <span style={{ fontSize: 13 }}>Total Applications Scanned</span>
            </div>


          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

            <AiFillFolderOpen size={50} style={{ color: '#FFA500' }} />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: 15 }}>
              <div style={{ color: '#FFA500', fontSize: 25 }}>{appStats.totalApplicationFindings}</div>
              <span style={{ fontSize: 13 }}>Total Application Findings</span>
            </div>


          </div>

        </div>



      </div>

    </>
  )
}

export default Applications