import React from 'react';

const VSCodeIntegration = () => {
  return (
    <div className="vscode-integration-guide">
      <h2 className="darkText">Visual Studio Code Integration for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Step 1: Install the Intrucept AppSecOps Extension</h3>
        <ol>
          <li>Open Visual Studio Code.</li>
          <li>Click on the Extensions icon in the left sidebar (it looks like a square made of four smaller squares).</li>
          <li>In the search bar at the top of the Extensions pane, type "Intrucept AppSecOps".</li>
          <li>Find the Intrucept AppSecOps extension in the search results.</li>
          <li>Click the "Install" button next to the extension.</li>
          <li>Wait for the installation to complete. You may need to reload VS Code after installation.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 2: Set Up intrucept-config.txt</h3>
        <ol>
          <li>In your project's root directory, create a new file named <code>intrucept-config.txt</code>.</li>
          <li>Open the file and add the following content:
            <pre>
              <code>
{`CLIENT_ID = your_intrucept_client_id
CLIENT_SECRET = your_intrucept_client_secret
APPLICATION_ID = your_intrucept_application_id`}
              </code>
            </pre>
          </li>
          <li>Replace the values with your actual Intrucept credentials.</li>
          <li>Save the file.</li>
        </ol>
        <p><strong>Note:</strong> Keep this file secure and do not commit it to version control.</p>
      </section>

      <section>
        <h3 className="darkText">Step 3: Open Your Project</h3>
        <ol>
          <li>In VS Code, go to File {'>'} Open Folder (or use the keyboard shortcut Ctrl+K Ctrl+O).</li>
          <li>Navigate to your project directory and select it.</li>
          <li>Click "Select Folder" to open the project in VS Code.</li>
          <li>Ensure that the <code>intrucept-config.txt</code> file is present in the root of your project directory.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 4: Perform SAST or SCA Scan</h3>
        <ol>
          <li>Open any file in your project by clicking on it in the file explorer.</li>
          <li>Right-click anywhere in the file content. This will open a context menu.</li>
          <li>In the context menu, you will see two new options:
            <ul>
              <li>"Perform SAST Scan"</li>
              <li>"Perform SCA Scan"</li>
            </ul>
          </li>
          <li>Click on the desired scan option to initiate the scan.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 5: View Scan Results</h3>
        <ol>
          <li>After initiating a scan, the progress will be shown in the Output window at the bottom of VS Code.</li>
          <li>If the Output window is not visible, you can open it by going to View {'>'} Output, or using the keyboard shortcut Ctrl+Shift+U.</li>
          <li>In the Output window, you should see a dropdown menu at the top right. Select "Intrucept AppSecOps" from this menu to view the scan output.</li>
          <li>The scan results, including any detected vulnerabilities, will be displayed in this output window once the scan is complete.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Important Notes</h3>
        <ul>
          <li>Ensure that the <code>intrucept-config.txt</code> file is always present in your project's root directory when running scans.</li>
          <li>Keep your credentials in the <code>intrucept-config.txt</code> file up to date.</li>
          <li>Do not share or commit the <code>intrucept-config.txt</code> file to version control systems to protect your credentials.</li>
          <li>If you encounter any issues with the scans, verify that your <code>intrucept-config.txt</code> file contains the correct and current credentials.</li>
        </ul>
      </section>
    </div>
  );
};

export default VSCodeIntegration;