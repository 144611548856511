import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CircularProgress from '@mui/material/CircularProgress';

const Organizations = () => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);

  const columns = [
    {
      name: 'name',
      label: 'Organization Name',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'usersCount',
      label: 'Users',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'applicationsCount',
      label: 'Applications',
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: 'issuesCount',
      label: 'Issues',
      options: {
        filter: true,
        sort: true,
      }
    },
    
    {
      name: 'createdAt',
      label: 'Created Date',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleDateString();
        }
      }
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton
                size="small"
                onClick={() => handleView(value)}
                color="primary"
                title="View"
              >
                <VisibilityIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleEdit(value)}
                color="primary"
                title="Edit"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => handleDeleteClick(organizations[tableMeta.rowIndex])}
                color="error"
                title="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        }
      }
    }
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    download: true,
    print: true,
    search: true,
    filter: true,
    viewColumns: true,
    customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/add-organization')}
        style={{ marginRight: '20px' }}
      >
        Add New Organization
      </Button>
    ),
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('api/v1/superadmin/organizations', {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      setOrganizations(response.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to fetch organizations');
      setLoading(false);
    }
  };

  const handleView = (orgId) => {
    navigate(`/view-organization?id=${orgId}`);
  };

  const handleEdit = (orgId) => {
    navigate(`/edit-organization?id=${orgId}`);
  };

  const handleDeleteClick = (org) => {
    setSelectedOrg(org);
    setDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    const bearerToken = localStorage.getItem('ASIToken');
    try {
      await axios.delete(`api/v1/superadmin/organizations/${selectedOrg._id}`, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success('Organization deleted successfully');
      fetchOrganizations();
      setDeleteDialog(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to delete organization');
    }
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ padding: '20px' }}>
      <MUIDataTable
        title="Organizations"
        data={organizations}
        columns={columns}
        options={options}
      />

      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete Organization"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedOrg?.name}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Organizations;