import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import EclipseIntegration from './ide/eclipse'
import IntellijIntegration from './ide/intellij'
import VisualStudioIntegration from './ide/visualStudio'
import VisualStudioCodeIntegration from './ide/visualStudioCode'


import { CopyToClipboard } from 'react-copy-to-clipboard';

const IDEIntegrations = () => {

  const navigate = useNavigate()
  const [organization, setOrganization] = useState({})
  const [onLoading, setOnLoading] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientIdCopied, setClientIdCopied] = useState(false);

  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretCopied, setClientSecretCopied] = useState(false);

  const toaster = useRef()

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {

    window.scrollTo(0, 0);

    loadOrganizationDetails();

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadOrganizationDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/organization-details/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    setOrganization(response.data.organization);
    setClientId(response.data.organization.clientId)
    setClientSecret(response.data.organization.clientSecret)

    setOnLoading(false);

  };


  const notifyClientIdCopied = async () => {

    toast('Client ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const notifyClientSecretCopied = async () => {

    toast('Client secret copied to clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }



  return (

    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '100%' }}>     

        
      <Tabs className="addAgentPage">
            <TabList style={{ borderWidth: 0, display: 'flex', justifyContent: 'flex-start' }}>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Eclipse</span>
              </Tab>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Intellij</span>
              </Tab>

              <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Visual Studio</span>
              </Tab>

               <Tab style={{ width: '30%', borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', }}>Visual Studio Code</span>
              </Tab>              

            </TabList>
            
            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

                <EclipseIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

                <IntellijIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

                <VisualStudioIntegration/>

            </TabPanel>               
             

             <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

                 <VisualStudioCodeIntegration/>

            </TabPanel>           
            

        </Tabs>
      </div>
    </div>
  )
}

export default IDEIntegrations